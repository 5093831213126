import React, {useEffect, useState} from "react";
import {ActionRowButton, ActionRowButtonProps, Icon, isEmpty} from "peggirkit";
import {ArrowDownOnSquareStackIcon} from "@heroicons/react/24/outline";
import {FormattedMessage, useIntl} from "react-intl";
import {buttonStyling} from "./projectButtons";
import {sanitizeFilename, saveProjectFile} from "../../../data/project";
import {LanguageOption, useEditor} from "../../../data/DataProvider";

const SaveProjectButton = (props: Partial<ActionRowButtonProps>) => {
    const intl = useIntl();
    const {
        contentLanguage,
        metaSlug,
        metaDescription,
        contentValue,
        hasMarkedContent,
        widgetData,
        autoSave
    } = useEditor();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!loading) {
            return;
        }

        let suggestedTitle;
        if (isEmpty(metaSlug)) {
            suggestedTitle = "project";
        } else {
            suggestedTitle = sanitizeFilename(metaSlug);
        }

        autoSave();
        saveProjectFile(suggestedTitle, {
            contentLanguage: contentLanguage as LanguageOption,
            metaSlug,
            metaDescription,
            contentValue,
            hasMarkedContent,
            widgetData,
        });
        setLoading(false);
    }, [loading]);

    return (
        <ActionRowButton
            {...props}
            {...buttonStyling}
            iconBefore={<Icon icon={ArrowDownOnSquareStackIcon}/>}
            buttonAction={{as: "button", actionProps: {onClick: () => setLoading(true)}}}
            title={intl.formatMessage({id: "save"})}
            disabled={loading}
            loading={loading}
        >
            <FormattedMessage id={"save"}/>
        </ActionRowButton>
    );
};

export default SaveProjectButton;