import React, {ReactNode, useEffect, useMemo, useState} from "react";
import Quill from "quill";
import {SelectOptionProps} from "peggirkit/dist/components/Input/Select/Select";
import {defaultEditorValue, defaultMetaDescription, defaultSlug} from "../editor/defaultValue";
import {AutoSaveFn, ClearProjectFn, LoadProjectFn, ProjectFileData, saveLocally} from "./project";

export type WidgetData = {
    [key: string]: any, // Data that can be read/written by widgets
};

export type Language = "en-US" | "nl-NL";

export type LanguageOption = {
    value: Language,
    displayValue: string,
};

export const languageOptions: LanguageOption[] = [
    {value: "en-US", displayValue: "English, United States (en-US)"},
    {value: "nl-NL", displayValue: "Nederlands (nl-NL)"},
];

export type SetHasMarkedContentFn = React.Dispatch<React.SetStateAction<boolean>>;

interface DataProviderInterface {
    contentLanguage: SelectOptionProps<{}>,
    setContentLanguage: React.Dispatch<React.SetStateAction<SelectOptionProps<{}>>>,
    editor?: Quill,
    setEditor: React.Dispatch<React.SetStateAction<Quill | undefined>>,
    metaSlug: string,
    setMetaSlug: React.Dispatch<React.SetStateAction<string>>,
    metaDescription: string,
    setMetaDescription: React.Dispatch<React.SetStateAction<string>>,
    contentValue: string,
    setContentValue: React.Dispatch<React.SetStateAction<string>>,
    widgetData: WidgetData,
    setWidgetData: React.Dispatch<React.SetStateAction<WidgetData>>,
    loadProject: LoadProjectFn,
    clearProject: ClearProjectFn,
    autoSave: AutoSaveFn,
    autoSavedOn: Date | null,
    // Used to keep tracked on whether the contents contain the MarkerBlot.
    // This is more efficient than checking after each editor contents change:
    hasMarkedContent: boolean,
    setHasMarkedContent: SetHasMarkedContentFn,
}

const DataContext = React.createContext<DataProviderInterface>({} as DataProviderInterface);

const useEditor = () => {
    return React.useContext<DataProviderInterface>(DataContext);
};

type Props = {
    initialProject: ProjectFileData | null,
    children: ReactNode,
};

const DataProvider = ({initialProject, children}: Props) => {
    const [editor, setEditor] = useState<Quill>();
    const [autoSavedOn, setAutoSavedOn] = useState<Date | null>(null);
    const [contentLanguage, setContentLanguage] = useState<SelectOptionProps<{}>>(initialProject === null
        ? languageOptions[0]
        : initialProject.contentLanguage);
    const [metaSlug, setMetaSlug] = useState<string>(initialProject === null
        ? defaultSlug
        : initialProject.metaSlug);
    const [metaDescription, setMetaDescription] = useState<string>(initialProject === null
        ? defaultMetaDescription
        : initialProject.metaDescription);
    const [contentValue, setContentValue] = useState<string>(initialProject === null
        ? defaultEditorValue
        : initialProject.contentValue);
    const [hasMarkedContent, setHasMarkedContent] = useState<boolean>(initialProject === null
        ? false
        : initialProject.hasMarkedContent);
    const [widgetData, setWidgetData] = useState<WidgetData>(initialProject === null
        ? {}
        : initialProject.widgetData);

    const autoSave: AutoSaveFn = () => {
        saveLocally({
            metaSlug,
            metaDescription,
            contentValue,
            hasMarkedContent,
            widgetData,
            contentLanguage: contentLanguage as LanguageOption,
        });
        setAutoSavedOn(new Date());
    };

    const loadProject: LoadProjectFn = ({
                                            contentLanguage,
                                            metaSlug,
                                            metaDescription,
                                            contentValue,
                                            hasMarkedContent,
                                            widgetData,
                                        }: ProjectFileData) => {
        setContentLanguage(contentLanguage);
        setMetaSlug(metaSlug);
        setMetaDescription(metaDescription);
        setContentValue(contentValue);
        setHasMarkedContent(hasMarkedContent);
        setWidgetData(widgetData);
    };

    const clearProject: ClearProjectFn = () => {
        setMetaSlug("");
        setMetaDescription("");
        setContentValue("");
        setHasMarkedContent(false);
        setWidgetData({});
    };

    // Auto-save interval
    useEffect(() => {
        const autoSaveInterval = setInterval(autoSave, 300000);
        return () => clearInterval(autoSaveInterval);
    }, [autoSave]);

    // Auto-save on ctrl+s or cmd+s (on Mac)
    useEffect(() => {
        const handler = (e: KeyboardEvent) => {
            // Refactor macOS platform detection, it may contain false-positives
            if (e.key === "s" && (navigator.userAgent.includes("Macintosh;") ? e.metaKey : e.ctrlKey)) {
                e.preventDefault();
                autoSave();
            }
        };

        window.addEventListener("keydown", handler);

        return () => {
            window.removeEventListener("keydown", handler);
        };
    }, [autoSave]);

    const value: DataProviderInterface = useMemo(() => ({
        contentLanguage,
        setContentLanguage,
        editor,
        setEditor,
        metaSlug,
        setMetaSlug,
        metaDescription,
        setMetaDescription,
        contentValue,
        setContentValue,
        hasMarkedContent,
        setHasMarkedContent,
        widgetData,
        setWidgetData,
        loadProject,
        clearProject,
        autoSave,
        autoSavedOn,
    }), [editor, setEditor, metaSlug, setMetaSlug, metaDescription, setMetaDescription, contentLanguage,
        setContentLanguage, contentValue, setContentValue, hasMarkedContent, setHasMarkedContent, widgetData,
        setWidgetData, loadProject, clearProject, autoSave, autoSavedOn]);

    return (
        <DataContext.Provider value={value}>
            {children}
        </DataContext.Provider>
    );
};

export {useEditor, DataProvider};