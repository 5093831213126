import {KeyphrasesForm} from "../../SeoWidget";
import nlp from "compromise";
import {functionWords} from "./functionWords";
import {GeneralScore} from "../../../../elements/scoreIndicator/ScoreIndicator";
import {Language} from "../../../../../data/DataProvider";

// Based on https://yoast.com/why-keyphrase-length-matters/

export type Keyphrase = {
    keyphrase: string,
    keyphraseSuggestion?: string,
};

export type KeyphraseLengthScore = {
    score: GeneralScore,
    keyphrases: Keyphrase[]
};

export const checkKeyphraseLength = (form: KeyphrasesForm, lang: Language): KeyphraseLengthScore => {
    const keyphrases: string[] = [form.keyphrase, ...form.keyphraseSynonyms];
    const matchJson = functionWords[lang].map((word: string) => ({word: word, optional: true}));
    const output: Keyphrase[] = [];
    let allFourOrLessWords = true;
    let hasEqualOrMoreThanSixWordsKeyphrase = false;

    keyphrases.map((keyphrase: string) => {
        const doc = nlp(keyphrase);
        const wordCount = doc.wordCount();
        if (wordCount > 4) {
            allFourOrLessWords = false;
        }

        if (wordCount >= 6) {
            hasEqualOrMoreThanSixWordsKeyphrase = true;
        }

        // @ts-ignore
        doc.match(matchJson).remove();
        const keyphraseWithoutFunctionWords = doc.text().trim();

        output.push({
            keyphrase: keyphrase,
            keyphraseSuggestion: keyphrase.length !== keyphraseWithoutFunctionWords.length
                ? keyphraseWithoutFunctionWords
                : undefined
        });
    });

    return {
        score: keyphrases.length > 0 && allFourOrLessWords
            ? "good"
            : (keyphrases.length === 0 || hasEqualOrMoreThanSixWordsKeyphrase ? "bad" : "okay"),
        keyphrases: output
    };
};