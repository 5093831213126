import {SeoFormProps} from "../../SeoWidget";
import nlp from "compromise";
import {GeneralScore} from "../../../../elements/scoreIndicator/ScoreIndicator";

// Based on https://yoast.com/meta-descriptions/

export type KeyphraseInDescriptionScore = {
    score: GeneralScore,
    densityPercentage: number,
    descriptionWordCount: number,
    keyphrasesInDescriptionWordCount: number,
    keyphrasesInDescription: string[],
};

export const checkKeyphraseInDescription = (description: string, form: SeoFormProps): KeyphraseInDescriptionScore => {
    const doc = nlp(description);

    const descriptionWordCount = doc.wordCount();
    let keyphrasesInDescriptionWordCount = 0;

    const keyphrases: string[] = [form.keyphrase, ...form.keyphraseSynonyms];
    const keyphrasesInDescription: string[] = [];

    keyphrases.map((keyphrase: string) => {
        doc.fullSentences().map((sentence: any) => {
            const keyphraseTerms = nlp(keyphrase).termList();
            let sentenceContainsKeyphrase = true;
            keyphraseTerms.forEach((term: any) => {
                sentenceContainsKeyphrase = sentenceContainsKeyphrase && sentence.has(term.text)
            });

            if (sentenceContainsKeyphrase) {
                keyphrasesInDescriptionWordCount += keyphraseTerms.length;
                keyphrasesInDescription.push(keyphrase);
            }
        });
    });

    const densityPercentage = Math.round((keyphrasesInDescriptionWordCount / descriptionWordCount) * 100);

    return {
        score: keyphrasesInDescription.length === 0
            ? "bad"
            : (densityPercentage >= 40 ? "okay" : "good"),
        densityPercentage: densityPercentage,
        descriptionWordCount: descriptionWordCount,
        keyphrasesInDescriptionWordCount: keyphrasesInDescriptionWordCount,
        keyphrasesInDescription: keyphrasesInDescription
    };
};