import React from "react";
import {Route, Routes} from "react-router-dom";
import Main from "./components/pages/Main";
import {NotificationProvider, ResponsiveProvider} from "peggirkit";

const App = () => {
    return (
        <ResponsiveProvider>
            <NotificationProvider
                position={"bottom-center"}
                breakpoints={{
                    md: {
                        position: "top-right",
                    },
                }}
            >
                <Routes>
                    <Route
                        path={"/"}
                        element={<Main/>}
                    />
                </Routes>
            </NotificationProvider>
        </ResponsiveProvider>
    );
}

export default App;
