import React from "react";
import {useIntl} from "react-intl";
import {DescriptionList, DescriptionListItem} from "peggirkit";
import {KeyphraseInDescriptionScore} from "./keyphraseInDescription/KeyphraseInDescriptionChecker";
import KeyphraseInDescriptionScoreIndicator
    from "../description/keyphraseInDescription/KeyphraseInDescriptionScoreIndicator";
import {DescriptionLengthScore} from "./descriptionLength/DescriptionLengthChecker";
import DescriptionLengthScoreIndicator from "../description/descriptionLength/DescriptionLengthScoreIndicator";

export type DescriptionScores = {
    keyphraseInDescription: KeyphraseInDescriptionScore,
    descriptionLength: DescriptionLengthScore,
};

type Props = {
    scores?: DescriptionScores,
};

const SeoDescriptionScores = ({scores}: Props) => {
    const intl = useIntl();
    return (
        <DescriptionList compact={true}>
            <DescriptionListItem
                title={intl.formatMessage({id: "keyphraseInDescription"})}
                content={<KeyphraseInDescriptionScoreIndicator result={scores?.keyphraseInDescription}/>}
            />
            <DescriptionListItem
                title={intl.formatMessage({id: "descriptionLength"})}
                content={<DescriptionLengthScoreIndicator result={scores?.descriptionLength}/>}
            />
        </DescriptionList>
    );
};

export default SeoDescriptionScores;