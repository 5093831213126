import {KeyphrasesForm} from "../../SeoWidget";
import * as cheerio from "cheerio";
import {GeneralScore} from "../../../../elements/scoreIndicator/ScoreIndicator";

// Based on https://yoast.com/meta-descriptions/

export type HeadingWithKeyphrases = {
    heading: string,
    keyphrases: string[]
};

export type KeyphraseInSubheadingScore = {
    score: GeneralScore,
    headingCount: number,
    headingKeyphrasePercentage: number,
    headingsWithKeyphrases: HeadingWithKeyphrases[]
};

export const checkKeyphraseInSubheading = (htmlContent: string, form: KeyphrasesForm): KeyphraseInSubheadingScore => {
    const keyphrases: string[] = [form.keyphrase, ...form.keyphraseSynonyms];
    const $ = cheerio.load(htmlContent);
    const headingEls = $("h1, h2");
    const headingCount = headingEls.length;
    const headingsWithKeyphrases: HeadingWithKeyphrases[] = [];

    headingEls.each((_, el) => {
        const headingEl = $(el);
        const headingText: string = headingEl.text();
        const includedKeyphrases: string [] = [];

        keyphrases.forEach((keyphrase: string) => {
            if (headingText.toLowerCase().includes(keyphrase)) {
                includedKeyphrases.push(keyphrase);
            }
        });

        if (includedKeyphrases.length > 0) {
            headingsWithKeyphrases.push({
                heading: headingText,
                keyphrases: includedKeyphrases
            });
        }
    });

    const headingKeyphrasePercentage = Math.round((headingsWithKeyphrases.length / headingCount) * 100);

    return {
        score: headingKeyphrasePercentage < 30
            ? "bad"
            : (headingKeyphrasePercentage > 75 ? "okay" : "good"),
        headingKeyphrasePercentage: headingKeyphrasePercentage,
        headingCount: headingCount,
        headingsWithKeyphrases: headingsWithKeyphrases,
    };
};