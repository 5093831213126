import React, {ReactNode, useMemo, useState} from "react";
import Toolbar from "../toolbar/Toolbar";
import {breakpointEqualOrGreaterThan, classNames, useResponsive} from "peggirkit";
import Editor from "../editor/Editor";
import Sidebar from "./Sidebar";
import RemoveMarkerButton from "../editor/marker/RemoveMarkerButton";
import AboutModal from "../about/AboutModal";

export const containerPadding = "pl-4 sm:pl-6 md:pl-8 pr-4 sm:pr-6 md:pr-8 pt-4 sm:pt-6 md:pt-8 pb-4 sm:pb-6 md:pb-8";

const Layout = () => {
    const {breakpoint} = useResponsive();
    const [showWidgetsSlider, setShowWidgetsSlider] = useState(false);
    const useStaticSidebar: ReactNode = useMemo(() => breakpointEqualOrGreaterThan(breakpoint, "lg"), [breakpoint]);
    const [showInfoModal, setShowInfoModal] = useState(false);

    return (
        <>
            <Toolbar
                setShowWidgetsSlider={setShowWidgetsSlider}
                setShowInfoModal={setShowInfoModal}
            />

            <div className={"absolute w-screen h-screen left-0 top-0 pt-[63px]"}>
                <div className={"w-full flex flex-row items-start h-full relative"}>
                    <div
                        className={classNames(
                            "scroll-container relative w-full h-full overflow-y-auto",
                            containerPadding
                        )}
                    >
                        <RemoveMarkerButton/>
                        <Editor/>
                    </div>
                    <Sidebar
                        staticSidebar={useStaticSidebar}
                        showWidgetsSlider={showWidgetsSlider}
                        setShowWidgetsSlider={setShowWidgetsSlider}
                    />
                </div>
            </div>

            <AboutModal
                show={showInfoModal}
                setShow={setShowInfoModal}
            />
        </>
    );
};

export default Layout;