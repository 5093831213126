// https://yoast.com/features/function-words/
// https://github.com/Yoast/wordpress-seo/blob/trunk/packages/yoastseo/src/languageProcessing/languages/nl/config/functionWords.js

import {Language} from "../../../../../data/DataProvider";

type FunctionWordsMap = {
    [key in Language]: string[];
};

export const functionWords: FunctionWordsMap = {
    "nl-NL": [
        "de", "het", "een", "der", "des", "den", "eén", "één", "twee", "drie", "vier", "vijf", "zes", "zeven", "acht",
        "negen", "tien", "elf", "twaalf", "dertien", "veertien", "vijftien", "zestien", "zeventien", "achttien",
        "negentien", "twintig", "honderd", "honderden", "duizend", "duizenden", "miljoen", "miljoenen", "biljoen",
        "biljoenen", "eerste", "tweede", "derde", "vierde", "vijfde", "zesde", "zevende", "achtste", "negende", "tiende",
        "elfde", "twaalfde", "dertiende", "veertiende", "vijftiende", "zestiende", "zeventiende", "achttiende",
        "negentiende", "twinstigste", "ik", "je", "jij", "hij", "ze", "we", "wij", "jullie", "zij", "u", "ge", "gij", "men",
        "mij", "jou", "hem", "haar", "hen", "hun", "uw", "dit", "dat", "deze", "die", "zelf", "mijn", "mijne", "jouw",
        "jouwe", "zijne", "hare", "ons", "onze", "hunne", "uwe", "elkaars", "elkanders", "alle", "sommige", "sommigen",
        "weinig", "weinige", "weinigen", "veel", "vele", "velen", "geen", "beetje", "elke", "elk", "genoeg", "meer",
        "meest", "meeste", "meesten", "paar", "zoveel", "enkele", "enkelen", "zoveelste", "hoeveelste", "laatste",
        "laatsten", "iedere", "allemaal", "zekere", "ander", "andere", "gene", "enig", "enige", "verscheidene",
        "verschillende", "voldoende", "allerlei", "allerhande", "enerlei", "enerhande", "beiderlei", "beiderhande",
        "tweeërlei", "tweeërhande", "drieërlei", "drieërhande", "velerlei", "velerhande", "menigerlei", "menigerhande",
        "enigerlei", "enigerhande", "generlei", "generhande", "mezelf", "mijzelf", "jezelf", "jouzelf", "zichzelf",
        "haarzelf", "hemzelf", "onszelf", "julliezelf", "henzelf", "hunzelf", "uzelf", "zich", "mekaar", "elkaar",
        "elkander", "mekander", "iedereen", "ieder", "eenieder", "alleman", "allen", "alles", "iemand", "niemand", "iets",
        "niets", "menigeen", "ieders", "aller", "iedereens", "eenieders", "welke", "welk", "wat", "wie", "wiens", "wier",
        "hoe", "waarom", "waar", "hoezo", "hoeveel", "daaraan", "daarachter", "daaraf", "daarbij", "daarbinnen",
        "daarboven", "daarbuiten", "daardoorheen", "daarheen", "daarin", "daarjegens", "daarmede", "daarnaar",
        "daarnaartoe", "daaromtrent", "daaronder", "daarop", "daarover", "daaroverheen", "daarrond", "daartegen",
        "daartussen", "daartussenuit", "daaruit", "daarvan", "daarvandaan", "eraan", "erachter", "erachteraan", "eraf",
        "erbij", "erbinnen", "erboven", "erbuiten", "erdoor", "erdoorheen", "erheen", "erin", "erjegens", "ermede", "ermee",
        "erna", "ernaar", "ernaartoe", "ernaast", "erom", "eromtrent", "eronder", "eronderdoor", "erop", "eropaf",
        "eropuit", "erover", "eroverheen", "errond", "ertegen", "ertegenaan", "ertoe", "ertussen", "ertussenuit", "eruit",
        "ervan", "ervandaan", "ervandoor", "ervoor", "hieraan", "hierachter", "hieraf", "hierbij", "hierbinnen",
        "hierboven", "hierbuiten", "hierdoor", "hierdoorheen", "hierheen", "hierin", "hierjegens", "hierlangs", "hiermede",
        "hiermee", "hierna", "hiernaar", "hiernaartoe", "hiernaast", "hieromheen", "hieromtrent", "hieronder", "hierop",
        "hierover", "hieroverheen", "hierrond", "hiertegen", "hiertoe", "hiertussen", "hiertussenuit", "hieruit", "hiervan",
        "hiervandaan", "hiervoor", "vandaan", "waaraan", "waarachter", "waaraf", "waarbij", "waarboven", "waarbuiten",
        "waardoorheen", "waarheen", "waarin", "waarjegens", "waarmede", "waarna", "waarnaar", "waarnaartoe", "waarnaast",
        "waarop", "waarover", "waaroverheen", "waarrond", "waartegen", "waartegenin", "waartoe", "waartussen",
        "waartussenuit", "waaruit", "waarvan", "waarvandaan", "waarvoor", "daar", "hier", "ginder", "daarginds", "ginds",
        "ver", "veraf", "ergens", "nergens", "overal", "dichtbij", "kortbij", "word", "wordt", "werd", "werden", "ben",
        "bent", "is", "was", "waren", "worden", "zijn", "heb", "hebt", "heeft", "hadden", "had", "kun", "kan", "kunt",
        "kon", "konden", "mag", "mocht", "mochten", "dien", "dient", "diende", "dienden", "moet", "moest", "moesten", "ga",
        "gaat", "ging", "gingen", "hebben", "kunnen", "mogen", "dienen", "moeten", "gaan", "blijkt", "blijk", "bleek",
        "bleken", "gebleken", "dunkt", "dunk", "dunkte", "dunkten", "gedunkt", "heet", "heette", "heetten", "geheten",
        "lijkt", "lijk", "geleken", "leek", "leken", "schijn", "schijnt", "scheen", "schenen", "toescheen", "toeschijnt",
        "toeschijn", "toeschenen", "blijken", "dunken", "heten", "lijken", "schijnen", "toeschijnen", "à", "aan",
        "aangaande", "achter", "behalve", "behoudens", "beneden", "benevens", "benoorden", "benoordoosten", "benoordwesten",
        "beoosten", "betreffende", "bewesten", "bezijden", "bezuiden", "bezuidoosten", "bezuidwesten", "bij", "binnen",
        "blijkens", "boven", "bovenaan", "buiten", "circa", "conform", "contra", "cum", "dankzij", "door", "gedurende",
        "gezien", "in", "ingevolge", "inzake", "jegens", "krachtens", "langs", "luidens", "met", "middels", "na", "naar",
        "naast", "nabij", "namens", "nevens", "niettegenstaande", "nopens", "om", "omstreeks", "omtrent", "onder",
        "onderaan", "ongeacht", "onverminderd", "op", "over", "overeenkomstig", "per", "plus", "post", "richting", "rond",
        "rondom", "spijts", "staande", "te", "tegen", "tegenover", "ten", "ter", "tijdens", "tot", "tussen", "uit", "van",
        "vanaf", "vanuit", "versus", "via", "vis-à-vis", "volgens", "voor", "voorbij", "wegens", "zijdens", "zonder", "af",
        "heen", "mee", "toe", "achterop", "onderin", "voorin", "bovenop", "buitenop", "achteraan", "onderop", "binnenin",
        "tevoren", "en", "alsmede", "of", "ofwel", "en/of", "zowel", "evenmin", "zomin", "hetzij", "vermits", "dewijl",
        "dorodien", "naardien", "nademaal", "overmits", "wijl", "eer", "eerdat", "aleer", "vooraleer", "alvorens", "totdat",
        "zolang", "sinds", "sedert", "ingeval", "tenware", "alhoewel", "hoezeer", "uitgezonderd", "zoverre", "zover",
        "naargelang", "naarmate", "alsof", "zegt", "zei", "vraagt", "vroeg", "denkt", "dacht", "stelt", "pleit", "pleitte",
        "absoluut", "zeker", "ongetwijfeld", "sowieso", "onmiddelijk", "meteen", "inclusief", "direct", "ogenblikkelijk",
        "terstond", "natuurlijk", "vanzelfsprekend", "gewoonlijk", "normaliter", "doorgaans", "werkelijk", "daadwerkelijk",
        "inderdaad", "waarachtig", "oprecht", "bijna", "meestal", "misschien", "waarschijnlijk", "wellicht", "mogelijk",
        "vermoedelijk", "allicht", "aannemelijk", "oorspronkelijk", "aanvankelijk", "initieel", "eigenlijk", "feitelijk",
        "wezenlijk", "juist", "reeds", "alvast", "bijv.", "vaak", "dikwijls", "veelal", "geregeld", "menigmaal",
        "regelmatig", "veelvuldig", "eenvoudigweg", "simpelweg", "louter", "kortweg", "stomweg", "domweg", "zomaar",
        "eventueel", "mogelijkerwijs", "eens", "weleens", "nooit", "ooit", "anders", "momenteel", "thans", "incidenteel",
        "trouwens", "elders", "volgend", "recent", "onlangs", "recentelijk", "laatst", "zojuist", "relatief", "duidelijk",
        "overduidelijk", "klaarblijkelijk", "nadrukkelijk", "ogenschijnlijk", "kennelijk", "schijnbaar", "alweer",
        "continu", "herhaaldelijk", "nog", "steeds", "nu", "zeer", "erg", "redelijk", "flink", "tikkeltje", "bijzonder",
        "ernstig", "enigszins", "zo", "tamelijk", "nogal", "behoorlijk", "zwaar", "heel", "hele", "reuze", "buitengewoon",
        "ontzettend", "vreselijk", "laat", "liet", "lieten", "kom", "komt", "kwam", "kwamen", "maakt", "maak", "maakte",
        "maakten", "doe", "doet", "deed", "deden", "vindt", "vind", "vond", "vonden", "laten", "komen", "maken", "doen",
        "vinden", "nieuw", "nieuwe", "nieuwer", "nieuwere", "nieuwst", "nieuwste", "oud", "oude", "ouder", "oudere",
        "oudst", "oudste", "vorig", "vorige", "goed", "goede", "beter", "betere", "best", "beste", "groot", "grote",
        "groter", "grotere", "grootst", "grootste", "makkelijk", "makkelijke", "makkelijker", "makkelijkere", "makkelijkst",
        "makkelijste", "gemakkelijk", "gemakkelijke", "gemakkelijker", "gemakkelijkere", "gemakkelijkst", "gemakkelijste",
        "simpel", "simpele", "simpeler", "simpelere", "simpelst", "simpelste", "snel", "snelle", "sneller", "snellere",
        "snelst", "snelste", "verre", "verder", "verdere", "verst", "verste", "lang", "lange", "langer", "langere",
        "langst", "langste", "hard", "harde", "harder", "hardere", "hardst", "hardste", "minder", "mindere", "minst",
        "minste", "eigen", "laag", "lage", "lager", "lagere", "laagst", "laagste", "hoog", "hoge", "hoger", "hogere",
        "hoogst", "hoogste", "klein", "kleine", "kleiner", "kleinere", "kleinst", "kleinste", "kort", "korte", "korter",
        "kortere", "kortst", "kortste", "herhaaldelijke", "directe", "ongeveer", "slecht", "slechte", "slechter",
        "slechtere", "slechtst", "slechtste", "zulke", "zulk", "zo'n", "zulks", "er", "extreem", "extreme", "bijbehorende",
        "bijbehorend", "niet", "oh", "wauw", "hèhè", "hè", "hé", "au", "ai", "jaja", "welja", "jawel", "ssst",
        "heremijntijd", "hemeltjelief", "aha", "foei", "hmm", "nou", "nee", "tja", "nja", "okido", "ho", "halt", "komaan",
        "komop", "verrek", "nietwaar", "brr", "oef", "ach", "och", "bah", "enfin", "afijn", "haha", "hihi", "hatsjie",
        "hatsjoe", "hm", "tring", "vroem", "boem", "hopla", "ml", "cl", "dl", "l", "tl", "el", "mg", "g", "gr", "kg", "ca",
        "theel", "min", "sec", "uur", "seconde", "secondes", "seconden", "minuut", "minuten", "uur", "uren", "dag", "dagen",
        "week", "weken", "maand", "maanden", "jaar", "jaren", "vandaag", "morgen", "overmorgen", "gisteren", "eergisteren",
        "'s", "morgens", "avonds", "middags", "nachts", "ding", "dingen", "manier", "manieren", "item", "items", "keer",
        "maal", "procent", "geval", "aspect", "persoon", "personen", "deel", "wel", "ja", "neen", "oké", "oke", "okee",
        "ok", "zoiets", "€", "euro", "mevr", "dhr", "mr", "dr", "prof", "jr", "sr", "aangezien", "al", "aldus",
        "allereerst", "als", "alsook", "anderzijds", "bijgevolg", "bijvoorbeeld", "bovendien", "concluderend", "daardoor",
        "daarentegen", "daarmee", "daarna", "daarnaast", "daarom", "daartoe", "daarvoor", "dadelijk", "dan", "desondanks",
        "dienovereenkomstig", "dientegevolge", "doch", "doordat", "dus", "echter", "eerst", "evenals", "eveneens",
        "evenzeer", "hierom", "hoewel", "immers", "indien", "integendeel", "intussen", "kortom", "later", "maar", "mits",
        "nadat", "namelijk", "net als", "niettemin", "noch", "ofschoon", "omdat", "ondanks", "ondertussen", "ook", "opdat",
        "resumerend", "samengevat", "samenvattend", "tegenwoordig", "teneinde", "tenzij", "terwijl", "tevens", "toch",
        "toen", "uiteindelijk", "vanwege", "vervolgens", "voorafgaand", "vooralsnog", "voordat", "voorts", "vroeger",
        "waardoor", "waarmee", "waaronder", "wanneer", "want", "zoals", "zodat", "zodoende", "zodra",
    ],
    "en-US": [

        "am",
        "is",
        "are",
        "was",
        "were",
        "been",
        "get",
        "gets",
        "got",
        "gotten",
        "be",
        "she's",
        "he's",
        "it's",
        "i'm",
        "we're",
        "they're",
        "you're",
        "that's",
        "isn't",
        "weren't",
        "wasn't",
        "aren't",
        "being",
        "getting",
        "having",
        "what's",
        "accordingly", "additionally", "afterward", "afterwards", "albeit", "also", "although", "altogether",
        "another", "basically", "because", "before", "besides", "but", "certainly", "chiefly", "comparatively",
        "concurrently", "consequently", "contrarily", "conversely", "correspondingly", "despite", "doubtedly", "during",
        "e.g.", "earlier", "emphatically", "equally", "especially", "eventually", "evidently", "explicitly", "finally",
        "firstly", "following", "formerly", "forthwith", "fourthly", "further", "furthermore", "generally", "hence",
        "henceforth", "however", "i.e.", "identically", "indeed", "initially", "instead", "last", "lastly", "later", "lest",
        "likewise", "markedly", "meanwhile", "moreover", "nevertheless", "nonetheless", "nor", "notwithstanding", "obviously",
        "occasionally", "otherwise", "overall", "particularly", "presently", "previously", "rather", "regardless", "secondly",
        "shortly", "significantly", "similarly", "simultaneously", "since", "so", "soon", "specifically", "still", "straightaway",
        "subsequently", "surely", "surprisingly", "than", "then", "thereafter", "therefore", "thereupon", "thirdly", "though",
        "thus", "till", "undeniably", "undoubtedly", "unless", "unlike", "unquestionably", "until", "when", "whenever",
        "whereas", "while",
        "above all", "after all", "after that", "all in all", "all of a sudden", "all things considered",
        "analogous to", "although this may be true", "analogous to", "another key point", "as a matter of fact", "as a result",
        "as an illustration", "as can be seen", "as has been noted", "as I have noted", "as I have said", "as I have shown",
        "as long as", "as much as", "as opposed to", "as shown above", "as soon as", "as well as", "at any rate", "at first", "at last",
        "at least", "at length", "at the present time", "at the same time", "at this instant", "at this point", "at this time",
        "balanced against", "being that", "by all means", "by and large", "by comparison", "by the same token", "by the time",
        "compared to", "be that as it may", "coupled with", "different from", "due to", "equally important", "even if",
        "even more", "even so", "even though", "first thing to remember", "for example", "for fear that", "for instance",
        "for one thing", "for that reason", "for the most part", "for the purpose of", "for the same reason", "for this purpose",
        "for this reason", "from time to time", "given that", "given these points", "important to realize", "in a word", "in addition",
        "in another case", "in any case", "in any event", "in brief", "in case", "in conclusion", "in contrast",
        "in detail", "in due time", "in effect", "in either case", "in essence", "in fact", "in general", "in light of",
        "in like fashion", "in like manner", "in order that", "in order to", "in other words", "in particular", "in reality",
        "in short", "in similar fashion", "in spite of", "in sum", "in summary", "in that case", "in the event that",
        "in the final analysis", "in the first place", "in the fourth place", "in the hope that", "in the light of",
        "in the long run", "in the meantime", "in the same fashion", "in the same way", "in the second place",
        "in the third place", "in this case", "in this situation", "in time", "in truth", "in view of", "inasmuch as",
        "most compelling evidence", "most important", "must be remembered", "not only", "not to mention", "note that",
        "now that", "of course", "on account of", "on balance", "on condition that", "on one hand", "on the condition that", "on the contrary",
        "on the negative side", "on the other hand", "on the positive side", "on the whole", "on this occasion", "once",
        "once in a while", "only if", "owing to", "point often overlooked", "prior to", "provided that", "seeing that",
        "so as to", "so far", "so long as", "so that", "sooner or later", "such as", "summing up", "take the case of",
        "that is", "that is to say", "then again", "this time", "to be sure", "to begin with", "to clarify", "to conclude",
        "to demonstrate", "to emphasize", "to enumerate", "to explain", "to illustrate", "to list", "to point out",
        "to put it another way", "to put it differently", "to repeat", "to rephrase it", "to say nothing of", "to sum up",
        "to summarize", "to that end", "to the end that", "to this end", "together with", "under those circumstances", "until now",
        "up against", "up to the present time", "vis a vis", "what's more", "while it may be true", "while this may be true",
        "with attention to", "with the result that", "with this in mind", "with this intention", "with this purpose in mind",
        "without a doubt", "without delay", "without doubt", "without reservation", "the", "an", "a", "one", "two", "three", "four", "five", "six", "seven", "eight", "nine", "ten", "eleven", "twelve", "thirteen",
        "fourteen", "fifteen", "sixteen", "seventeen", "eighteen", "nineteen", "twenty", "hundred", "hundreds", "thousand", "thousands",
        "million", "millions", "billion", "billions", "first", "second", "third", "fourth", "fifth", "sixth", "seventh", "eighth", "ninth", "tenth",
        "eleventh", "twelfth", "thirteenth", "fourteenth", "fifteenth", "sixteenth", "seventeenth", "eighteenth",
        "nineteenth", "twentieth", "i", "you", "he", "she", "it", "we", "they", "me", "him", "us", "them", "this", "that", "these", "those", "my", "your", "his", "her", "its", "their", "our", "mine", "yours", "hers", "theirs", "ours"
        , "all", "some", "many", "lot", "lots", "ton", "tons", "bit", "no", "every", "enough", "little",
        "much", "more", "most", "plenty", "several", "few", "fewer", "kind", "kinds", "myself", "yourself", "himself", "herself", "itself", "oneself", "ourselves", "yourselves", "themselves", "none", "nobody", "everyone", "everybody", "someone", "somebody", "anyone", "anybody", "nothing",
        "everything", "something", "anything", "each", "other", "whatever", "whichever", "whoever", "whomever",
        "whomsoever", "whosoever", "others", "neither", "both", "either", "any", "such", "one's", "nobody's", "everyone's", "everybody's", "someone's", "somebody's", "anyone's",
        "anybody's", "nothing's", "everything's", "something's", "anything's", "whoever's", "others'", "other's", "another's",
        "neither's", "either's", "which", "what", "whose", "who", "whom", "where", "how", "why", "whether", "wherever", "whyever", "wheresoever", "whensoever", "howsoever",
        "whysoever", "whatsoever", "whereso", "whomso", "whenso", "howso", "whyso", "whoso", "whatso", "therefor", "therein", "hereby", "hereto", "wherein", "therewith", "herewith", "wherewith", "thereby", "there", "here", "whither", "thither", "hither", "whence", "thence", "always", "once", "twice", "thrice", "can", "cannot", "can't", "could", "couldn't", "could've", "dare", "dares", "dared", "do",
        "don't", "does", "doesn't", "did", "didn't", "done", "have", "haven't", "had", "hadn't", "has", "hasn't",
        "i've", "you've", "we've", "they've", "i'd", "you'd", "he'd", "she'd", "it'd", "we'd", "they'd", "would", "wouldn't",
        "would've", "may", "might", "must", "need", "needn't", "needs", "ought", "shall", "shalln't", "shan't", "should",
        "shouldn't", "will", "won't", "i'll", "you'll", "he'll", "she'll", "it'll", "we'll", "they'll", "there's", "there're",
        "there'll", "here's", "here're", "there'll", "appear", "appears", "appeared", "become", "becomes", "became", "come", "comes", "came", "keep", "keeps", "kept",
        "remain", "remains", "remained", "stay", "stays", "stayed", "turn", "turns", "turned", "doing", "daring", "having", "appearing", "becoming", "coming", "keeping", "remaining", "staying",
        "saying", "asking", "stating", "seeming", "letting", "making", "setting", "showing", "putting", "adding", "going", "using",
        "trying", "containing", "in", "from", "with", "under", "throughout", "atop", "for", "on", "of", "to", "aboard", "about",
        "above", "abreast", "absent", "across", "adjacent", "after", "against", "along", "alongside", "amid", "mid",
        "among", "apropos", "apud", "around", "as", "astride", "at", "ontop", "afore", "tofore", "behind", "ahind",
        "below", "ablow", "beneath", "neath", "beside", "between", "atween", "beyond", "ayond", "by", "chez",
        "circa", "spite", "down", "except", "into", "less", "like", "minus", "near", "nearer", "nearest", "anear", "notwithstanding",
        "off", "onto", "opposite", "out", "outen", "over", "past", "per", "pre", "qua", "sans", "sauf", "sithence", "through",
        "thru", "truout", "toward", "underneath", "up", "upon", "upside", "versus", "via", "vis-à-vis", "without", "ago",
        "apart", "aside", "aslant", "away", "withal", "towards", "amidst", "amongst", "midst", "whilst", "back", "within", "forward", "backward", "ahead", "and", "or", "and/or", "yet", "sooner", "just", "only", "if", "even", "say", "says", "said", "claimed", "ask", "asks", "asked", "stated", "explain", "explains", "explained",
        "think", "thinks", "talks", "talked", "announces", "announced", "tells", "told", "discusses", "discussed", "suggests",
        "suggested", "understands", "understood", "again", "definitely", "eternally", "expressively", "instead",
        "expressly", "immediately", "including", "instantly", "namely", "naturally", "next", "notably", "now", "nowadays",
        "ordinarily", "positively", "truly", "ultimately", "uniquely", "usually", "almost", "maybe",
        "probably", "granted", "initially", "too", "actually", "already", "e.g", "i.e", "often", "regularly", "simply",
        "optionally", "perhaps", "sometimes", "likely", "never", "ever", "else", "inasmuch", "provided", "currently", "incidentally",
        "elsewhere", "particular", "recently", "relatively", "f.i", "clearly", "apparently", "highly", "very", "really", "extremely", "absolutely", "completely", "totally", "utterly", "quite",
        "somewhat", "seriously", "fairly", "fully", "amazingly", "seem", "seems", "seemed", "let", "let's", "lets", "make", "makes", "made", "want", "showed", "shown",
        "go", "goes", "went", "gone", "take", "takes", "took", "taken", "put", "puts", "use", "used", "try", "tries", "tried", "mean",
        "means", "meant", "called", "based", "add", "adds", "added", "contain", "contains", "contained", "consist", "consists",
        "consisted", "ensure", "ensures", "ensured", "new", "newer", "newest", "old", "older", "oldest", "previous", "good", "well", "better", "best",
        "big", "bigger", "biggest", "easy", "easier", "easiest", "fast", "faster", "fastest", "far", "hard", "harder", "hardest",
        "least", "own", "large", "larger", "largest", "long", "longer", "longest", "low", "lower", "lowest", "high", "higher",
        "highest", "regular", "simple", "simpler", "simplest", "small", "smaller", "smallest", "tiny", "tinier", "tiniest",
        "short", "shorter", "shortest", "main", "actual", "nice", "nicer", "nicest", "real", "same", "able", "certain", "usual",
        "so-called", "mainly", "mostly", "recent", "anymore", "complete", "lately", "possible", "commonly", "constantly",
        "continually", "directly", "easily", "nearly", "slightly", "somewhere", "estimated", "latest", "different", "similar",
        "widely", "bad", "worse", "worst", "great", "specific", "available", "average", "awful", "awesome", "basic", "beautiful",
        "busy", "current", "entire", "everywhere", "important", "major", "multiple", "normal", "necessary", "obvious", "partly",
        "special", "last", "early", "earlier", "earliest", "young", "younger", "youngest", "oh", "wow", "tut-tut", "tsk-tsk", "ugh", "whew", "phew", "yeah", "yea", "shh", "oops", "ouch", "aha",
        "yikes", "tbs", "tbsp", "spk", "lb", "qt", "pk", "bu", "oz", "pt", "mod", "doz", "hr", "f.g", "ml", "dl", "cl",
        "l", "mg", "g", "kg", "quart", "seconds", "minute", "minutes", "hour", "hours", "day", "days", "week", "weeks", "month", "months",
        "year", "years", "today", "tomorrow", "yesterday", "thing", "things", "way", "ways", "matter", "case", "likelihood", "ones", "piece", "pieces", "stuff", "times",
        "part", "parts", "percent", "instance", "instances", "aspect", "aspects", "item", "items", "idea", "theme", "person", "instance",
        "instances", "detail", "details", "factor", "factors", "difference", "differences", "not", "yes", "sure", "top", "bottom", "ok", "okay", "amen", "aka", "etc", "etcetera", "sorry", "please", "ms", "mss", "mrs", "mr", "dr", "prof", "jr", "sr"
    ],
};
