// https://github.com/Yoast/wordpress-seo/blob/trunk/packages/yoastseo/src/languageProcessing/languages/en/config/transitionWords.js

import {WordsLanguageMap} from "../TransitionWordsChecker";
import {Language} from "../../../../../data/DataProvider";

export const singleWords: WordsLanguageMap = {
    "en-US": ["accordingly", "additionally", "afterward", "afterwards", "albeit", "also", "although", "altogether",
        "another", "basically", "because", "before", "besides", "but", "certainly", "chiefly", "comparatively",
        "concurrently", "consequently", "contrarily", "conversely", "correspondingly", "despite", "doubtedly", "during",
        "e.g.", "earlier", "emphatically", "equally", "especially", "eventually", "evidently", "explicitly", "finally",
        "firstly", "following", "formerly", "forthwith", "fourthly", "further", "furthermore", "generally", "hence",
        "henceforth", "however", "i.e.", "identically", "indeed", "initially", "instead", "last", "lastly", "later", "lest",
        "likewise", "markedly", "meanwhile", "moreover", "nevertheless", "nonetheless", "nor", "notwithstanding", "obviously",
        "occasionally", "otherwise", "overall", "particularly", "presently", "previously", "rather", "regardless", "secondly",
        "shortly", "significantly", "similarly", "simultaneously", "since", "so", "soon", "specifically", "still", "straightaway",
        "subsequently", "surely", "surprisingly", "than", "then", "thereafter", "therefore", "thereupon", "thirdly", "though",
        "thus", "till", "undeniably", "undoubtedly", "unless", "unlike", "unquestionably", "until", "when", "whenever",
        "whereas", "while"],
    "nl-NL": ["aangezien", "al", "aldus", "allereerst", "als", "alsook", "anderzijds", "bijgevolg", "bijvoorbeeld", "bovendien",
        "concluderend", "daardoor", "daarentegen", "daarmee", "daarna", "daarnaast", "daarom", "daartoe", "daarvoor", "dadelijk", "dan",
        "desondanks", "dienovereenkomstig", "dientegevolge", "doch", "doordat", "dus", "echter", "eerst", "evenals", "eveneens", "evenzeer",
        "hierom", "hoewel", "immers", "indien", "integendeel", "intussen", "kortom", "later", "maar", "mits", "nadat", "namelijk", "net als",
        "niettemin", "noch", "ofschoon", "omdat", "ondanks", "ondertussen", "ook", "opdat", "resumerend", "samengevat", "samenvattend",
        "tegenwoordig", "teneinde", "tenzij", "terwijl", "tevens", "toch", "toen", "uiteindelijk", "vanwege", "vervolgens", "voorafgaand",
        "vooralsnog", "voordat", "voorts", "vroeger", "waardoor", "waarmee", "waaronder", "wanneer", "want", "zoals", "zodat", "zodoende",
        "zodra"],
};

export const multipleWords: WordsLanguageMap = {
    "en-US": ["above all", "after all", "after that", "all in all", "all of a sudden", "all things considered",
        "analogous to", "although this may be true", "analogous to", "another key point", "as a matter of fact", "as a result",
        "as an illustration", "as can be seen", "as has been noted", "as I have noted", "as I have said", "as I have shown",
        "as long as", "as much as", "as opposed to", "as shown above", "as soon as", "as well as", "at any rate", "at first", "at last",
        "at least", "at length", "at the present time", "at the same time", "at this instant", "at this point", "at this time",
        "balanced against", "being that", "by all means", "by and large", "by comparison", "by the same token", "by the time",
        "compared to", "be that as it may", "coupled with", "different from", "due to", "equally important", "even if",
        "even more", "even so", "even though", "first thing to remember", "for example", "for fear that", "for instance",
        "for one thing", "for that reason", "for the most part", "for the purpose of", "for the same reason", "for this purpose",
        "for this reason", "from time to time", "given that", "given these points", "important to realize", "in a word", "in addition",
        "in another case", "in any case", "in any event", "in brief", "in case", "in conclusion", "in contrast",
        "in detail", "in due time", "in effect", "in either case", "in essence", "in fact", "in general", "in light of",
        "in like fashion", "in like manner", "in order that", "in order to", "in other words", "in particular", "in reality",
        "in short", "in similar fashion", "in spite of", "in sum", "in summary", "in that case", "in the event that",
        "in the final analysis", "in the first place", "in the fourth place", "in the hope that", "in the light of",
        "in the long run", "in the meantime", "in the same fashion", "in the same way", "in the second place",
        "in the third place", "in this case", "in this situation", "in time", "in truth", "in view of", "inasmuch as",
        "most compelling evidence", "most important", "must be remembered", "not only", "not to mention", "note that",
        "now that", "of course", "on account of", "on balance", "on condition that", "on one hand", "on the condition that", "on the contrary",
        "on the negative side", "on the other hand", "on the positive side", "on the whole", "on this occasion", "once",
        "once in a while", "only if", "owing to", "point often overlooked", "prior to", "provided that", "seeing that",
        "so as to", "so far", "so long as", "so that", "sooner or later", "such as", "summing up", "take the case of",
        "that is", "that is to say", "then again", "this time", "to be sure", "to begin with", "to clarify", "to conclude",
        "to demonstrate", "to emphasize", "to enumerate", "to explain", "to illustrate", "to list", "to point out",
        "to put it another way", "to put it differently", "to repeat", "to rephrase it", "to say nothing of", "to sum up",
        "to summarize", "to that end", "to the end that", "to this end", "together with", "under those circumstances", "until now",
        "up against", "up to the present time", "vis a vis", "what's more", "while it may be true", "while this may be true",
        "with attention to", "with the result that", "with this in mind", "with this intention", "with this purpose in mind",
        "without a doubt", "without delay", "without doubt", "without reservation"],
    "nl-NL": ["aan de andere kant", "aan de ene kant", "aangenomen dat", "al met al", "alles afwegend", "alles bij elkaar",
        "alles in aanmerking nemend", "als gevolg van", "anders gezegd", "daar staat tegenover", "daarbij komt", "daaruit volgt",
        "dat betekent", "dat blijkt uit", "de oorzaak daarvan is", "de oorzaak hiervan is", "door middel van", "een voorbeeld hiervan",
        "een voorbeeld van", "gesteld dat", "hetzelfde als", "hieruit kunnen we afleiden", "hieruit volgt", "hoe het ook zij",
        "in de derde plaats", "in de eerste plaats", "in de tweede plaats", "in één woord", "in het bijzonder", "in het geval dat",
        "in plaats van", "in tegenstelling tot", "in vergelijking met", "maar ook", "met als doel", "met andere woorden", "met behulp van",
        "met de bedoeling", "neem nou", "net als", "om kort te gaan", "onder andere", "op dezelfde wijze", "stel dat", "te danken aan",
        "te wijten aan", "ten derde", "ten eerste", "ten gevolge van", "ten slotte", "ten tweede", "ter conclusie", "ter illustratie",
        "ter verduidelijking", "tot nog toe", "tot slot", "vandaar dat", "vergeleken met", "voor het geval dat"],
};

export const allWords = (lang: Language): string[] => singleWords[lang].concat(multipleWords[lang]);

export default allWords;