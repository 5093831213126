import React from "react";
import {classNames, Select, TextareaField, TextField} from "peggirkit";
import InputLabel from "peggirkit/dist/components/Input/InputElements/InputControl/InputLabel";
import {languageOptions, useEditor} from "../data/DataProvider";
import {FormattedMessage, useIntl} from "react-intl";
import TextAddOn from "peggirkit/dist/components/Input/Field/TextField/TextFieldAddOn/TextAddOn";
import {containerPadding} from "../layout/Layout";

const MetadataSettings = () => {
    const {
        contentLanguage,
        setContentLanguage,
        metaSlug,
        setMetaSlug,
        metaDescription,
        setMetaDescription
    } = useEditor();
    const intl = useIntl();

    return (
        <div className={classNames("w-full flex flex-col gap-6 md:gap-12", containerPadding)}>
            <Select
                label={
                    <InputLabel>
                        <FormattedMessage id={"contentLanguage"}/>
                    </InputLabel>
                }
                tip={intl.formatMessage({id: "contentLanguage.tip"})}
                displayValueEmpty={intl.formatMessage({id: "contentLanguage"})}
                options={languageOptions}
                value={contentLanguage}
                onChange={setContentLanguage}
            />

            <TextField
                type={"text"}
                label={
                    <InputLabel hint={intl.formatMessage({id: "n-characters"}, {n: metaSlug.length})}>
                        <FormattedMessage id={"slug"}/>
                    </InputLabel>
                }
                paddingLeft={0}
                leading={TextAddOn({
                    text: intl.formatMessage({id: "slugAddon"}),
                    classname: "opacity-50",
                    hideBorder: true,
                    paddingRight: 0,
                })}
                value={metaSlug}
                onChange={e => setMetaSlug(e.target.value)}
                tip={intl.formatMessage({id: "slug.tip"})}
            />

            <TextareaField
                className={"min-h-[120px]"}
                label={
                    <InputLabel hint={intl.formatMessage({id: "n-characters"}, {n: metaDescription.length})}>
                        <FormattedMessage id={"description"}/>
                    </InputLabel>
                }
                value={metaDescription}
                onChange={e => setMetaDescription(e.target.value)}
            />
        </div>
    );
};

export default MetadataSettings;