import React, {useEffect, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {maxWordsPerSection, Section, SubheadingDistributionScore} from "./SubheadingDistributionChecker";
import {Modal, Table, TableData} from "peggirkit";
import ScoreIndicator from "../../../elements/scoreIndicator/ScoreIndicator";
import {globalModalStyling} from "../../../elements/modal/modalStyle";

type Result = {
    section: string,
    words: number,
};

type Props = {
    result?: SubheadingDistributionScore
};

const SubheadingDistributionScoreIndicator = ({result}: Props) => {
    const intl = useIntl();
    const [showExplanation, setShowExplanation] = useState(false);
    const [results, setResults] = useState<TableData<Result>[]>([]);

    useEffect(() => {
        setResults(
            result?.sections.map((section: Section) => ({
                section: section.heading || intl.formatMessage({id: "noHeading"}),
                words: (
                    <span className={section.wordCount >= maxWordsPerSection ? "text-red-600" : "text-green-700"}>
                    {section.wordCount}
                </span>
                ),
            })) || []);
    }, [result?.sections]);

    if (!result) {
        return <>-</>;
    }

    return (
        <>
            <ScoreIndicator
                score={result.score}
                onClick={() => setShowExplanation(true)}
            />

            <Modal
                {...globalModalStyling(intl)}
                show={showExplanation}
                onClose={() => setShowExplanation(false)}
                title={intl.formatMessage({id: "subheadingDistribution"})}
            >
                <div className={"flex flex-col gap-6"}>
                    <p>
                        <FormattedMessage id="subheadingDistribution.description-1"/>
                    </p>

                    <Table<Result>
                        columns={[
                            {
                                id: "section",
                                label: {displayName: intl.formatMessage({id: "section"})},
                            },
                            {
                                id: "words",
                                label: {displayName: intl.formatMessage({id: "words"})},
                            },
                        ]}
                        data={results}
                        setData={setResults}
                    />
                </div>
            </Modal>
        </>
    );
};

export default SubheadingDistributionScoreIndicator;