// Based on https://yoast.com/meta-descriptions/#yoast-seo-meta-description
import {GeneralScore} from "../../../../elements/scoreIndicator/ScoreIndicator";

export type DescriptionLengthScore = {
    score: GeneralScore,
    characterCount: number,
};

export const checkDescriptionLength = (description: string): DescriptionLengthScore => {
    const characterCount = description.trim().length;
    return {
        score: characterCount >= 120 && characterCount <= 156 ? "good" : "bad",
        characterCount: characterCount
    };
};