import React from "react";
import {ActionRowButton} from "peggirkit";
import {AutoSaveFn} from "../../data/project";
import {FormattedMessage} from "react-intl";

const autoSaveButton = (autoSave: AutoSaveFn, autoSavedOn: Date | null) => (
    <ActionRowButton
        showInRowBreakpoint={"lg"}
        variant={"link"}
        color={"primary"}
        buttonAction={{
            as: "button",
            actionProps: {
                onClick: () => autoSave()
            },
        }}
    >
        {autoSavedOn === null
            ? <FormattedMessage id={"autoSave"}/>
            : <FormattedMessage
                id={"autoSavedOn"}
                values={{
                    time: autoSavedOn.toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit"
                    })
                }}
            />
        }
    </ActionRowButton>
);

export default autoSaveButton;