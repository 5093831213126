import React, {useMemo} from "react";
import {SlideOver} from "peggirkit";
import WidgetsBar from "../widgets/WidgetsBar";

type Props = {
    showWidgetsSlider: boolean,
    setShowWidgetsSlider: React.Dispatch<React.SetStateAction<boolean>>,
    staticSidebar: boolean,
};

const Sidebar = ({showWidgetsSlider, setShowWidgetsSlider, staticSidebar}: Props) => {
    const widgets = useMemo(() => <WidgetsBar/>, []);

    if (!staticSidebar) {
        return (
            <SlideOver
                skipPadding={true}
                open={showWidgetsSlider}
                setOpen={setShowWidgetsSlider}
                unmount={false}
            >
                {widgets}
            </SlideOver>
        );
    }

    return (
        <div
            className={"w-[400px] grow-0 shrink-0 h-full overflow-y-auto border-l border-gray-200 dark:border-slate-800"}
        >
            {widgets}
        </div>
    );
};

export default Sidebar;