import {SeoFormProps} from "../../SeoWidget";
import nlp from "compromise";
import {functionWords} from "../../content/keyphraseLength/functionWords";
import {GeneralScore} from "../../../../elements/scoreIndicator/ScoreIndicator";
import {Language} from "../../../../../data/DataProvider";

// Based on https://yoast.com/page-titles-seo/

export type KeyphraseInTitleScore = {
    score: GeneralScore,
};

export const checkKeyphraseInTitle = (title: string, form: SeoFormProps, lang: Language): KeyphraseInTitleScore => {
    const doc = nlp(title);
    const keyphraseInTitle: any[] = doc
        .match(form.keyphrase, undefined, {})
        .out("offset");

    if (keyphraseInTitle.length === 0) {
        // Not present
        return {score: "bad"};
    }

    const keyphraseInTitleIndex: number = keyphraseInTitle[0].offset.index;
    if (keyphraseInTitleIndex === 0 || functionWords[lang].includes(doc.terms(0).text().toLowerCase())) {
        // Title starts with keyphrase or with single function word followed by keyphrase
        return {score: "good"};
    }

    // Title contains keyphrase somewhere, but not at start
    return {
        score: "okay"
    };
};