import React, {useEffect, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import SeoContentScores, {ContentScores} from "./content/SeoContentScores";
import Quill from "quill";
import SeoTitleScores, {TitleScores} from "./title/SeoTitleScores";
import SeoDescriptionScores, {DescriptionScores} from "./description/SeoDescriptionScores";
import Widget, {CalculateScoreFn, CalculateScoreParams} from "../Widget";
import WidgetTabControl from "../../elements/tabControl/WidgetTabControl";
import WidgetTabPage from "../../elements/tabControl/WidgetTabPage";
import FootNote from "../../elements/footNote/FootNote";
import KeyphrasesInput from "./KeyphrasesInput";
import {checkLinkFocus} from "./content/linkFocus/LinkFocusChecker";
import {checkKeyphraseDistribution} from "./content/keyphraseDistribution/KeyphraseDistributionChecker";
import {checkKeyphraseDensity} from "./content/keyphraseDensity/KeyphraseDensityChecker";
import {checkKeyphraseLength} from "./content/keyphraseLength/KeyphraseLengthChecker";
import {checkKeyphraseInSubheading} from "./content/keyphraseInSubheading/KeyphraseInSubheadingChecker";
import {checkContentLength} from "./content/contentLength/ContentLengthChecker";
import {checkKeyphraseInIntroduction} from "./content/keyphraseInIntroduction/KeyphraseInIntroductionChecker";
import {markTexts} from "../../../editor/marker/Util";
import {Language, SetHasMarkedContentFn, useEditor} from "../../../data/DataProvider";
import {checkLinks, LinkScore} from "./content/links/LinksChecker";
import {isEmpty} from "peggirkit";
import {checkDescriptionLength} from "./description/descriptionLength/DescriptionLengthChecker";
import {checkKeyphraseInDescription} from "./description/keyphraseInDescription/KeyphraseInDescriptionChecker";
import {findContentTitle} from "../../../editor/editorUtils";
import {checkTitleLength} from "./title/titleLength/TitleLengthChecker";
import {checkKeyphraseInTitle} from "./title/keyphraseInTitle/KeyphraseInTitleChecker";
import {checkKeyphraseInSlug} from "./title/keyphraseInSlug/KeyphraseInSlugChecker";

export type SeoFormProps = {
    keyphrase: string,
    keyphraseSynonyms: string[],
};

export type SeoScoresProps = {
    seoForm: SeoFormProps
};

export const markAllKeyphrases = (setHasMarkedContent: SetHasMarkedContentFn, seoForm: SeoFormProps, quill?: Quill) => {
    // This does not mark keyphrases in different orders, but only exact matches (case-insensitive though)
    markTexts(setHasMarkedContent, [seoForm.keyphrase, ...seoForm.keyphraseSynonyms], quill, true, false);
};

export type KeyphrasesForm = {
    keyphrase: string,
    keyphraseSynonyms: string[],
};

export const seoWidgetId = "seo";

const SeoWidget = () => {
    const intl = useIntl();
    const {widgetData, setWidgetData} = useEditor();
    const [contentScores, setContentScores] = useState<ContentScores>();
    const [titleScores, setTitleScores] = useState<TitleScores>();
    const [descriptionScores, setDescriptionScores] = useState<DescriptionScores>();
    const [keyphrasesForm, setKeyPhrasesForm] = useState<KeyphrasesForm>({
        keyphrase: "",
        keyphraseSynonyms: [],
    });

    const setKeyphraseError = (message?: string) => {
        setWidgetData(prev => ({
            ...prev,
            [seoWidgetId]: {
                ...prev[seoWidgetId],
                keyphraseValidationError: message,
            },
        }));
    };

    useEffect(() => {
        const synonyms: string | undefined = widgetData[seoWidgetId]?.keyphraseSynonyms;
        setKeyPhrasesForm({
            keyphrase: widgetData[seoWidgetId]?.keyphrase || "",
            keyphraseSynonyms: synonyms?.split(",").map((kw: string) => kw.trim()) || [],
        })
    }, [widgetData[seoWidgetId]]);

    const calculateScore: CalculateScoreFn = ({
                                                  editor,
                                                  contentValue,
                                                  contentLanguage,
                                                  metaDescription,
                                                  metaSlug,
                                              }: CalculateScoreParams) => {
        if (isEmpty(keyphrasesForm.keyphrase)) {
            setKeyphraseError(intl.formatMessage({id: "enterKeyphrase"}));
            return;
        }

        setKeyphraseError(undefined);
        const lang: Language = contentLanguage.value as Language;

        if (editor) {
            const linkScores: LinkScore = checkLinks(contentValue, editor.getText());
            setContentScores({
                outboundLinks: linkScores.outboundScore,
                internalLinks: linkScores.internalScore,
                keyphraseLinkFocus: checkLinkFocus(linkScores, keyphrasesForm),
                keyphraseDistribution: checkKeyphraseDistribution(editor.getText(), keyphrasesForm),
                keyphraseDensity: checkKeyphraseDensity(editor.getText(), keyphrasesForm),
                keyphraseLength: checkKeyphraseLength(keyphrasesForm, lang),
                keyphraseInSubheading: checkKeyphraseInSubheading(contentValue, keyphrasesForm),
                contentLength: checkContentLength(editor.getText()),
                keyphraseInIntroduction: checkKeyphraseInIntroduction(contentValue, keyphrasesForm),
            });
        }

        const title = findContentTitle(contentValue);
        setTitleScores({
            keyphraseInTitle: checkKeyphraseInTitle(title, keyphrasesForm, lang),
            keyphraseInSlug: checkKeyphraseInSlug(metaSlug, keyphrasesForm),
            titleLength: checkTitleLength(title),
        });

        setDescriptionScores({
            keyphraseInDescription: checkKeyphraseInDescription(metaDescription, keyphrasesForm),
            descriptionLength: checkDescriptionLength(metaDescription),
        });
    }

    return (
        <Widget
            name={intl.formatMessage({id: "seo"})}
            calculateScore={calculateScore}
        >
            <KeyphrasesInput/>

            <WidgetTabControl>
                <WidgetTabPage name={intl.formatMessage({id: "content"})}>
                    <SeoContentScores
                        seoForm={keyphrasesForm}
                        scores={contentScores}
                    />
                    <FootNote>
                        <FormattedMessage id={"theseContentScoresIncludeTitle"}/>
                    </FootNote>
                </WidgetTabPage>

                <WidgetTabPage name={intl.formatMessage({id: "title"})}>
                    <SeoTitleScores scores={titleScores}/>
                </WidgetTabPage>

                <WidgetTabPage name={intl.formatMessage({id: "description"})}>
                    <SeoDescriptionScores scores={descriptionScores}/>
                </WidgetTabPage>
            </WidgetTabControl>
        </Widget>
    );
};

export default SeoWidget;