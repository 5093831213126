import React, {useState} from "react";
import {Alert, DescriptionList, DescriptionListItem} from "peggirkit";
import {useIntl} from "react-intl";
import WordComplexityStatus, {calcWordComplexity, WordComplexityResults} from "./WordComplexityStatus";
import Widget, {CalculateScoreFn, CalculateScoreParams} from "../Widget";
import {markText, unmarkText} from "../../../editor/marker/Util";
import {useEditor} from "../../../data/DataProvider";
import {findContentTitle} from "../../../editor/editorUtils";


/**
 * {@link https://yoast.com/word-complexity-assessment/}
 */
const WordComplexityWidget = () => {
    const intl = useIntl();
    const {setHasMarkedContent, contentLanguage} = useEditor();
    const [titleComplexity, setTitleComplexity] = useState<WordComplexityResults | null>(null);
    const [descriptionComplexity, setDescriptionComplexity] = useState<WordComplexityResults | null>(null);
    const [contentComplexity, setContentComplexity] = useState<WordComplexityResults | null>(null);

    const calculateScore: CalculateScoreFn = ({editor, contentValue, metaDescription}: CalculateScoreParams) => {
        const title = findContentTitle(contentValue);
        setTitleComplexity(calcWordComplexity(title));
        setDescriptionComplexity(calcWordComplexity(metaDescription));

        if (editor) {
            const newContentComplexity = calcWordComplexity(editor.getText());
            unmarkText(editor);
            newContentComplexity?.uniqueComplexWords.map((word: string) => markText(setHasMarkedContent, word, editor));
            setContentComplexity(newContentComplexity);
        }
    };

    return (
        <Widget
            name={intl.formatMessage({id: "wordComplexity"})}
            calculateScore={calculateScore}
        >
            {
                contentLanguage.value !== "en-US" &&
                <Alert
                    containerClassname={"mb-6"}
                    type={"warning"}
                    title={intl.formatMessage({id: "wordComplexityDesignedForEnglish"})}
                />
            }

            <DescriptionList compact={true}>
                <DescriptionListItem
                    title={intl.formatMessage({id: "title"})}
                    content={<WordComplexityStatus result={titleComplexity}/>}
                />
                <DescriptionListItem
                    title={intl.formatMessage({id: "description"})}
                    content={<WordComplexityStatus result={descriptionComplexity}/>}
                />
                <DescriptionListItem
                    title={intl.formatMessage({id: "content"})}
                    content={<WordComplexityStatus result={contentComplexity}/>}
                />
            </DescriptionList>
        </Widget>
    );
};

export default WordComplexityWidget;