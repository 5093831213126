import React, {useEffect, useMemo, useState} from "react";
import {Modal, Table, TableData} from "peggirkit";
import {commonWords} from "./commonWords";
import {FormattedMessage, useIntl} from "react-intl";
import ScoreIndicator, {GeneralScore} from "../../elements/scoreIndicator/ScoreIndicator";
import {globalModalStyling} from "../../elements/modal/modalStyle";

type TableResult = {
    complexWord: string,
};

export type WordComplexityResults = {
    complexWords: string[],
    uniqueComplexWords: string[]
    totalWordCount: number,
    complexWordsPercentage: number,
};

type Props = {
    result: WordComplexityResults | null
};

export const calcWordComplexity = (inputText: string): (WordComplexityResults | null) => {
    // Parse words
    const words: (string[] | null) = inputText.match(/\w+/g);
    if (words === null) {
        // No words in text
        return null;
    }

    // Find complex words
    const complexWords: string[] = [];
    words.forEach((word: string) => {
        if (word.length > 7 && !word.match(/^[A-Z]/) && !commonWords.has(word)) {
            complexWords.push(word);
        }
    });

    return {
        complexWords: complexWords,
        complexWordsPercentage: (complexWords.length / words.length) * 100,
        uniqueComplexWords: [...new Set(complexWords)],
        totalWordCount: words.length
    };
};

const WordComplexityStatus = ({result}: Props) => {
    const intl = useIntl();
    const [showResult, setShowResult] = useState(false);
    const [tableData, setTableData] = useState<TableData<TableResult>[]>([]);
    const complexityScore: GeneralScore = useMemo(() => {
        if (result === null) {
            return null;
        }

        if (result.complexWordsPercentage <= 10) {
            return "good";
        } else if (result.complexWordsPercentage >= 90) {
            return "bad";
        }

        return "okay";
    }, [result, result?.complexWords, result?.totalWordCount]);

    useEffect(() => {
        setTableData(
            result?.uniqueComplexWords.map(word => ({
                complexWord: word
            })) || [])
    }, [result?.uniqueComplexWords]);

    if (result === null) {
        return <>-</>;
    }

    return (
        <div>
            <ScoreIndicator
                score={complexityScore}
                onClick={() => setShowResult(true)}
            />

            <Modal
                {...globalModalStyling(intl)}
                show={showResult}
                onClose={() => setShowResult(false)}
                title={intl.formatMessage({id: "complexWordsAnalysis"})}
            >
                <div className={"flex flex-col gap-6"}>
                    <p>
                        <FormattedMessage id="complexWordsAnalysis.description-1"/>
                    </p>

                    <p>
                        <FormattedMessage
                            id="complexWordsAnalysis.description-2"
                            values={{
                                nComplexWords: result.complexWords.length,
                                totalWordCount: result.totalWordCount,
                                complexWordsPercentage: Math.round(result.complexWordsPercentage),
                            }}
                        />
                    </p>

                    <div className={"max-h-96 overflow-y-auto p-1"}>
                        <Table<TableResult>
                            columns={[
                                {
                                    id: "complexWord",
                                    label: {displayName: intl.formatMessage({id: "complexWords"})},
                                }
                            ]}
                            data={tableData}
                            setData={setTableData}
                        />
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default WordComplexityStatus;