import React from "react";
import ReadabilityWidget from "./readability/ReadabilityWidget";
import FleschKincaidWidget from "./fleschKincaid/FleschKincaidWidget";
import SeoWidget from "./seo/SeoWidget";
import WordComplexityWidget from "./wordComplexity/WordComplexityWidget";

const SidebarScores = () => {
    return (
        <div
            className={"w-full flex flex-col divide-y divide-gray-200 dark:divide-slate-700 last:border-b last:border-gray-200 last:dark:border-slate-700"}
        >
            <SeoWidget/>
            <FleschKincaidWidget/>
            <ReadabilityWidget/>
            <WordComplexityWidget/>
        </div>
    );
};

export default SidebarScores;