import React from "react";
import {useIntl} from "react-intl";
import {DescriptionList, DescriptionListItem} from "peggirkit";
import {KeyphraseInTitleScore} from "./keyphraseInTitle/KeyphraseInTitleChecker";
import KeyphraseInTitleScoreIndicator from "../title/keyphraseInTitle/KeyphraseInTitleScoreIndicator";
import {KeyphraseInSlugScore} from "./keyphraseInSlug/KeyphraseInSlugChecker";
import KeyphraseInSlugScoreIndicator from "../title/keyphraseInSlug/KeyphraseInSlugScoreIndicator";
import {TitleLengthScore} from "./titleLength/TitleLengthChecker";
import TitleLengthScoreIndicator from "../title/titleLength/TitleLengthScoreIndicator";

export type TitleScores = {
    keyphraseInTitle: KeyphraseInTitleScore,
    keyphraseInSlug: KeyphraseInSlugScore,
    titleLength: TitleLengthScore,
};

type Props = {
    scores?: TitleScores,
};

const SeoTitleScores = ({scores,}: Props) => {
    const intl = useIntl();
    return (
        <DescriptionList compact={true}>
            <DescriptionListItem
                title={intl.formatMessage({id: "keyphraseInTitle"})}
                content={<KeyphraseInTitleScoreIndicator result={scores?.keyphraseInTitle}/>}
            />
            <DescriptionListItem
                title={intl.formatMessage({id: "keyphraseInSlug"})}
                content={<KeyphraseInSlugScoreIndicator result={scores?.keyphraseInSlug}/>}
            />
            <DescriptionListItem
                title={intl.formatMessage({id: "titleLength"})}
                content={<TitleLengthScoreIndicator result={scores?.titleLength}/>}
            />
        </DescriptionList>
    );
};

export default SeoTitleScores;