import nlp from "compromise";
import {GeneralScore} from "../../../elements/scoreIndicator/ScoreIndicator";

// Based on: https://yoast.com/the-sentence-length-check/

type LongSentence = {
    text: string,
    wordCount: number,
};

export type SentenceLengthScore = {
    score: GeneralScore
    sentenceCount: number,
    longSentences: LongSentence[],
    longSentencesPercentage: number,
};

export const checkSentenceLengths = (text: string): SentenceLengthScore => {
    const sentencesData = nlp(text).sentences().json();
    const longSentences: LongSentence[] = [];

    sentencesData.map((sentence: any) => {
        const wordCount = sentence.terms.length;
        if (wordCount > 20) {
            longSentences.push({
                text: sentence.text,
                wordCount: wordCount
            });
        }
    });

    const sentenceCount = sentencesData.length;
    const longSentencesPercentage = Math.round((longSentences.length / sentenceCount) * 100);

    return {
        score: longSentencesPercentage >= 25 ? "bad" : "good",
        longSentencesPercentage: longSentencesPercentage,
        longSentences: longSentences,
        sentenceCount: sentenceCount
    };
};