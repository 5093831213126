import React, {useState} from "react";
import Widget, {CalculateScoreFn, CalculateScoreParams} from "../Widget";
import {FormattedMessage, useIntl} from "react-intl";
import {flekin} from "flekin";
import {findContentTitle} from "../../../editor/editorUtils";
import FleschKincaidInfoModal from "./FleschKincaidInfoModal";
import FleschKincaidScoreList from "./FleschKincaidScoreList";
import WidgetTabControl from "../../elements/tabControl/WidgetTabControl";
import WidgetTabPage from "../../elements/tabControl/WidgetTabPage";
import FootNote from "../../elements/footNote/FootNote";
import {Alert} from "peggirkit";
import {useEditor} from "../../../data/DataProvider";

export type FleschKincaidScoreProps = {
    grade_level: number | null,
    reading_ease: number | null,
    word_count: number,
    syllable_count: number,
    sentence_count: number,
}

const FleschKincaidWidget = () => {
    const intl = useIntl();
    const {contentLanguage} = useEditor();
    const [showInfoModal, setShowInfoModal] = useState(false);
    const [contentScore, setContentScore] = useState<FleschKincaidScoreProps>();
    const [titleScore, setTitleScore] = useState<FleschKincaidScoreProps>();
    const [descriptionScore, setDescriptionScore] = useState<FleschKincaidScoreProps>();

    const calculateScore: CalculateScoreFn = ({editor, contentValue, metaDescription}: CalculateScoreParams) => {
        if (editor) {
            setContentScore(flekin(editor.getText() || "") as FleschKincaidScoreProps); // Includes the title
        }

        const title = findContentTitle(contentValue);
        setTitleScore(flekin(title) as FleschKincaidScoreProps);
        setDescriptionScore(flekin(metaDescription) as FleschKincaidScoreProps);
    };

    return (
        <Widget
            name={intl.formatMessage({id: "fleschKincaid"})}
            calculateScore={calculateScore}
            infoButtonOnClick={() => setShowInfoModal(true)}
        >
            {
                contentLanguage.value !== "en-US" &&
                <Alert
                    containerClassname={"mb-6"}
                    type={"warning"}
                    title={intl.formatMessage({id: "fleschKincaidDesignedForEnglish"})}
                />
            }

            <WidgetTabControl>
                <WidgetTabPage name={intl.formatMessage({id: "content"})}>
                    <FleschKincaidScoreList score={contentScore}/>
                    <FootNote>
                        <FormattedMessage id={"theseContentScoresIncludeTitle"}/>
                    </FootNote>
                </WidgetTabPage>

                <WidgetTabPage name={intl.formatMessage({id: "title"})}>
                    <FleschKincaidScoreList score={titleScore}/>
                </WidgetTabPage>

                <WidgetTabPage name={intl.formatMessage({id: "description"})}>
                    <FleschKincaidScoreList score={descriptionScore}/>
                </WidgetTabPage>
            </WidgetTabControl>

            <FleschKincaidInfoModal
                show={showInfoModal}
                setShow={setShowInfoModal}
            />
        </Widget>
    );
};

export default FleschKincaidWidget;