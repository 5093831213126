import React, {useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {TransitionWordsScore} from "./TransitionWordsChecker";
import {Modal} from "peggirkit";
import ScoreIndicator from "../../../elements/scoreIndicator/ScoreIndicator";
import {globalModalStyling} from "../../../elements/modal/modalStyle";

type Props = {
    result?: TransitionWordsScore
};

const TransitionWordsScoreIndicator = ({result}: Props) => {
    const intl = useIntl();
    const [showExplanation, setShowExplanation] = useState(false);

    if (!result) {
        return <>-</>;
    }

    return (
        <>
            <ScoreIndicator
                score={result.score}
                onClick={() => setShowExplanation(true)}
            />

            <Modal
                {...globalModalStyling(intl)}
                show={showExplanation}
                onClose={() => setShowExplanation(false)}
                title={intl.formatMessage({id: "transitionWords"})}
            >
                <div className={"flex flex-col gap-6"}>
                    <p>
                        <FormattedMessage id="transitionWords.description-1"/>
                    </p>

                    <p>
                        <span className={"text-black dark:text-white font-medium"}>
                            <FormattedMessage
                                id="transitionWords.description-2"
                                values={{
                                    sentencesWithTransition: result.sentencesWithTransition.length,
                                    sentenceCount: result.sentenceCount,
                                    sentencesWithTransitionPercentage: result.sentencesWithTransitionPercentage,
                                }}
                            />
                        </span>
                    </p>
                </div>
            </Modal>
        </>
    );
};

export default TransitionWordsScoreIndicator;