import {ModalProps} from "peggirkit";
import {IntlShape} from "react-intl";

export const globalModalResponsiveStyling: Partial<ModalProps> = {
    position: "center-bottom",
    breakpoints: {
        md: {
            position: "center",
        },
    },
};

export const globalModalStyling = (intl: IntlShape): Partial<ModalProps> => ({
    ...globalModalResponsiveStyling,
    closeButtonTitle: intl.formatMessage({id: "close"}),
});