import React, {useEffect, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {OutboundLinkScore} from "./LinksChecker";
import {Modal, Table, TableData} from "peggirkit";
import ScoreIndicator from "../../../../elements/scoreIndicator/ScoreIndicator";
import {globalModalStyling} from "../../../../elements/modal/modalStyle";

type TableResult = {
    text: string,
    href: string,
};

type Props = {
    result?: OutboundLinkScore
};

const OutboundLinkScoreIndicator = ({result}: Props) => {
    const intl = useIntl();
    const [showExplanation, setShowExplanation] = useState(false);
    const [tableData, setTableData] = useState<TableData<TableResult>[]>([]);

    useEffect(() => {
        setTableData(result?.links.map(link => ({
            text: link.text,
            href: link.href,
        })) || []);
    }, [result?.links]);

    if (!result) {
        return <>-</>;
    }

    return (
        <>
            <ScoreIndicator
                score={result.score}
                onClick={() => setShowExplanation(true)}
            />

            <Modal
                {...globalModalStyling(intl)}
                show={showExplanation}
                onClose={() => setShowExplanation(false)}
                title={intl.formatMessage({id: "outboundLinks"})}
            >
                <div className={"flex flex-col gap-6"}>
                    <p>
                        <FormattedMessage id="outboundLinks.description-1"/>
                    </p>
                    <p>
                        <span className={"text-black dark:text-white font-medium"}>
                            <FormattedMessage
                                id="outboundLinks.description-2"
                                values={{
                                    count: result.links.length,
                                }}
                            />
                        </span>
                    </p>

                    <Table
                        columns={[
                            {
                                id: "text",
                                label: {displayName: intl.formatMessage({id: "text"})},
                            },
                            {
                                id: "href",
                                label: {displayName: intl.formatMessage({id: "href"})},
                            },
                        ]}
                        data={tableData}
                        setData={setTableData}
                    />
                </div>
            </Modal>
        </>
    );
};

export default OutboundLinkScoreIndicator;