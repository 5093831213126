import React, {useState} from "react";
import {Modal, Table, TableData} from "peggirkit";
import {FormattedMessage, IntlShape, useIntl} from "react-intl";
import {globalModalStyling} from "../../elements/modal/modalStyle";

type Explanation = {
    score: string,
    level: string,
    note: string,
};

const kincaidScores = (intl: IntlShape): TableData<Explanation>[] => ([
    {
        score: intl.formatMessage({id: "fleschKincaid.1.score"}),
        level: intl.formatMessage({id: "fleschKincaid.1.level"}),
        note: intl.formatMessage({id: "fleschKincaid.1.note"})
    },
    {
        score: intl.formatMessage({id: "fleschKincaid.2.score"}),
        level: intl.formatMessage({id: "fleschKincaid.2.level"}),
        note: intl.formatMessage({id: "fleschKincaid.2.note"})
    },
    {
        score: intl.formatMessage({id: "fleschKincaid.3.score"}),
        level: intl.formatMessage({id: "fleschKincaid.3.level"}),
        note: intl.formatMessage({id: "fleschKincaid.3.note"})
    },
    {
        score: intl.formatMessage({id: "fleschKincaid.4.score"}),
        level: intl.formatMessage({id: "fleschKincaid.4.level"}),
        note: intl.formatMessage({id: "fleschKincaid.4.note"})
    },
    {
        score: intl.formatMessage({id: "fleschKincaid.5.score"}),
        level: intl.formatMessage({id: "fleschKincaid.5.level"}),
        note: intl.formatMessage({id: "fleschKincaid.5.note"})
    },
    {
        score: intl.formatMessage({id: "fleschKincaid.6.score"}),
        level: intl.formatMessage({id: "fleschKincaid.6.level"}),
        note: intl.formatMessage({id: "fleschKincaid.6.note"})
    },
    {
        score: intl.formatMessage({id: "fleschKincaid.7.score"}),
        level: intl.formatMessage({id: "fleschKincaid.7.level"}),
        note: intl.formatMessage({id: "fleschKincaid.7.note"})
    },
    {
        score: intl.formatMessage({id: "fleschKincaid.8.score"}),
        level: intl.formatMessage({id: "fleschKincaid.8.level"}),
        note: intl.formatMessage({id: "fleschKincaid.8.note"})
    },
]);

type Props = {
    show: boolean,
    setShow: React.Dispatch<React.SetStateAction<boolean>>,
};

const FleschKincaidInfoModal = ({show, setShow}: Props) => {
    const intl = useIntl();
    const [tableData, setTableData] = useState<TableData<Explanation>[]>(kincaidScores(intl));

    return (
        <Modal
            {...globalModalStyling(intl)}
            title={intl.formatMessage({id: "fleschKincaidReadabilityTests"})}
            show={show}
            onClose={() => setShow(false)}
            maxWidth={"max-w-4xl"}
        >
            <div className={"flex flex-col gap-6"}>
                <p>
                    <FormattedMessage id="fleschReadingEase.description"/>
                </p>
                <p>
                    <FormattedMessage id="fleschKincaidReadabilityTests.description.1"/>
                </p>
                <p>
                    <FormattedMessage id="fleschKincaidReadabilityTests.description.2"/>
                </p>

                <Table
                    columns={[
                        {
                            id: "score",
                            label: {displayName: intl.formatMessage({id: "score"})},
                            primary: true,
                        },
                        {
                            id: "level",
                            label: {displayName: intl.formatMessage({id: "level"})},
                        },
                        {
                            id: "note",
                            label: {displayName: intl.formatMessage({id: "note"})},
                        },
                    ]}
                    data={tableData}
                    setData={setTableData}
                />
            </div>
        </Modal>
    );
};

export default FleschKincaidInfoModal;