import React, {useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {Modal} from "peggirkit";
import {TitleLengthScore} from "./TitleLengthChecker";
import ScoreIndicator from "../../../../elements/scoreIndicator/ScoreIndicator";
import {globalModalStyling} from "../../../../elements/modal/modalStyle";

type Props = {
    result?: TitleLengthScore
};

const TitleLengthScoreIndicator = ({result}: Props) => {
    const intl = useIntl();
    const [showExplanation, setShowExplanation] = useState(false);

    if (!result) {
        return <>-</>;
    }

    return (
        <>
            <ScoreIndicator
                score={result.score}
                onClick={() => setShowExplanation(true)}
            />

            <Modal
                {...globalModalStyling(intl)}
                show={showExplanation}
                onClose={() => setShowExplanation(false)}
                title={intl.formatMessage({id: "titleLength"})}
            >
                <div className={"flex flex-col gap-6"}>
                    <p>
                        <FormattedMessage id="titleLength.description-1"/>
                    </p>
                    <p>
                        <FormattedMessage id="titleLength.description-2"/>
                    </p>
                    <p>
                        <FormattedMessage id="titleLength.description-3"/>
                    </p>
                    <p>
                        <span className={"text-black dark:text-white font-medium"}>
                            <FormattedMessage
                                id="titleLength.description-4"
                                values={{
                                    characterCount: result.characterCount,
                                    wordCount: result.wordCount,
                                }}
                            />
                        </span>
                    </p>
                </div>
            </Modal>
        </>
    );
};

export default TitleLengthScoreIndicator;