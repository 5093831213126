import React from "react";
// @ts-ignore
import iconBlue from "../../../assets/img/logo/peggir-icon-blue.png";
// @ts-ignore
import iconWhite from "../../../assets/img/logo/peggir-icon-white.svg";
import {Logo} from "peggirkit";
import {useIntl} from "react-intl";

const ToolbarLogo = () => {
    const intl = useIntl();

    return (
        <Logo
            src={iconBlue}
            darkSrc={iconWhite}
            alt={intl.formatMessage({id: "appName"})}
            title={intl.formatMessage({id: "appName"})}
            width={67}
            height={24}
        />
    );
};

export default ToolbarLogo;