import React from "react";
import {FleschKincaidScoreProps} from "./FleschKincaidWidget";
import {classNames, DescriptionList, DescriptionListItem} from "peggirkit";
import {useIntl} from "react-intl";

type Props = {
    score?: FleschKincaidScoreProps,
};

const FleschKincaidScoreList = ({score}: Props) => {
    const intl = useIntl();
    return (
        <DescriptionList compact={true}>
            <DescriptionListItem
                title={intl.formatMessage({id: "grade"})}
                content={score?.grade_level || "-"}
            />
            <DescriptionListItem
                title={intl.formatMessage({id: "ease"})}
                content={
                    score?.reading_ease
                        ? <span
                            className={classNames(
                                score.reading_ease >= 60
                                    ? "text-green-700 dark:text-green-400"
                                    : "text-red-700 dark:text-red-400"
                            )}
                        >
                                {score.reading_ease}
                            </span>
                        : "-"
                }
                tooltip={intl.formatMessage({id: "acceptableFleschKincaidScore"})}
            />
            <DescriptionListItem
                title={intl.formatMessage({id: "words"})}
                content={score?.word_count || "-"}
            />
            <DescriptionListItem
                title={intl.formatMessage({id: "sentences"})}
                content={score?.sentence_count || "-"}
            />
            <DescriptionListItem
                title={intl.formatMessage({id: "syllables"})}
                content={score?.syllable_count || "-"}
            />
        </DescriptionList>
    );
};

export default FleschKincaidScoreList;