import React, {ReactNode, useEffect, useState} from "react";
import {ActionRow, ActionRowButton, Card, classNames, Icon, SimpleCardHeading} from "peggirkit";
import CardHeader from "peggirkit/dist/components/Card/CardHeading/CardHeader";
import {ArrowPathRoundedSquareIcon, InformationCircleIcon} from "@heroicons/react/24/outline";
import CardHeaderCollapseButton from "peggirkit/dist/components/Card/CardHeading/CardHeaderCollapseButton";
import ChevronDownIcon from "@heroicons/react/24/outline/ChevronDownIcon";
import {useIntl} from "react-intl";
import {SelectOptionProps} from "peggirkit/dist/components/Input/Select/Select";
import Quill from "quill";
import {useEditor, WidgetData} from "../../data/DataProvider";

export type CalculateScoreParams = {
    contentLanguage: SelectOptionProps<{}>,
    editor?: Quill,
    metaSlug: string,
    metaDescription: string,
    contentValue: string,
    widgetData: WidgetData,
};

export type CalculateScoreFn = (params: CalculateScoreParams) => void;

type Props = {
    name: string,
    calculateScore: CalculateScoreFn,
    children: ReactNode | string,
    infoButtonOnClick?: () => void,
};

const Widget = ({name, calculateScore, infoButtonOnClick, children}: Props) => {
    const intl = useIntl();
    const {contentLanguage, contentValue, editor, metaSlug, metaDescription, widgetData} = useEditor();
    const [expanded, setExpanded] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!loading) {
            return;
        }

        calculateScore({contentLanguage, editor, metaSlug, metaDescription, contentValue, widgetData});
        setLoading(false);
    }, [loading, calculateScore]);

    return (
        <Card
            containerClassname={"group"}
            cardStyle={false}
            header={
                <CardHeader
                    actionRow={
                        <ActionRow>
                            <ActionRowButton
                                buttonAction={{
                                    as: "button",
                                    actionProps: {
                                        onClick: () => {
                                            setLoading(true);
                                            setExpanded(true);
                                        }
                                    }
                                }}
                                loading={loading}
                                iconBefore={<Icon icon={ArrowPathRoundedSquareIcon}/>}
                                color="secondary"
                                variant="text"
                                borderRadius={"rounded-full"}
                                iconOnly={true}
                                title={intl.formatMessage({id: "calculateScore"})}
                            />
                        </ActionRow>
                    }
                    collapsible={
                        <CardHeaderCollapseButton
                            open={expanded}
                            setOpen={setExpanded}
                            buttonProps={open => ({
                                iconBefore: <Icon
                                    icon={ChevronDownIcon}
                                    className={classNames(
                                        "transition-transform",
                                        open ? "rotate-180" : ""
                                    )}
                                />,
                                title: intl.formatMessage({id: open ? "collapse" : "expand"}),
                                iconOnly: true,
                                variant: "link",
                                color: "secondary",
                                buttonClassname: "!px-0 !rounded-full",
                            })}
                        />
                    }
                >
                    <SimpleCardHeading
                        title={
                            <div className={"flex flex-row items-center justify-start gap-2"}>
                                <button
                                    onClick={() => setExpanded(prev => !prev)}
                                    className={expanded ? "!text-blue-700 dark:!text-blue-500" : ""}
                                >
                                    {name}
                                </button>
                                {
                                    infoButtonOnClick &&
                                    <button
                                        title={intl.formatMessage({id: "aboutThisScore"})}
                                        onClick={() => infoButtonOnClick()}
                                    >
                                        <Icon
                                            icon={InformationCircleIcon}
                                            className={"shrink-0 grow-0 w-5 h-5 transition-opacity opacity-50 md:opacity-0 group-hover:opacity-75"}
                                        />
                                    </button>
                                }
                            </div>
                        }
                    />
                </CardHeader>
            }
        >
            <div className={"px-4 sm:px-6 pt-3 sm:pt-5 pb-6 sm:pb-10"}>
                {children}
            </div>
        </Card>
    );
};

export default Widget;