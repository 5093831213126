import React from "react";
import {ActionRowButton, Icon} from "peggirkit";
import {DocumentMagnifyingGlassIcon} from "@heroicons/react/24/outline";
import {FormattedMessage, useIntl} from "react-intl";

export type ToggleWidgetsSliderButtonProps = {
    setShowWidgetsSlider: React.Dispatch<React.SetStateAction<boolean>>,
};

const ToggleWidgetsSliderButton = ({setShowWidgetsSlider}: ToggleWidgetsSliderButtonProps) => {
    const intl = useIntl();

    return (
        <ActionRowButton
            buttonClassname={"lg:hidden"}
            buttonAction={{
                as: "button",
                actionProps: {
                    onClick: () => setShowWidgetsSlider(true)
                }
            }}
            iconBefore={<Icon icon={DocumentMagnifyingGlassIcon}/>}
            color={"secondary"}
            variant={"text"}
            borderRadius={"rounded-full"}
            iconOnly={true}
            title={intl.formatMessage({id: "scores"})}
            breakpoints={{md: {iconOnly: false, borderRadius: undefined}}}
        >
            <FormattedMessage id={"scores"}/>
        </ActionRowButton>
    );
};

export default ToggleWidgetsSliderButton;