import {LanguageOption} from "./DataProvider";
import {sanitizeFilename} from "./project";
import {findContentTitle} from "../editor/editorUtils";
import {NodeHtmlMarkdown} from "node-html-markdown";

const beautify_html = require('js-beautify').html;

export type File = {
    mime: "application/json" | "text/markdown" | "text/html",
    fileName: string,
    data: string,
};

export type ExportProjectData = {
    metaSlug: string,
    metaDescription: string,
    contentLanguage: LanguageOption,
    contentValue: string,
};

export const exportAsHtml = ({metaSlug, metaDescription, contentLanguage, contentValue}: ExportProjectData) => {
    const formattedHtml = beautify_html(contentValue, {
        indent_inner_html: true,
        indent_size: 4,
        extra_liners: ["p", "h1", "h2", "h3", "h4", "h5", "h6", "ol", "ul", "img", "picture", "placeholder-img",
            "div", "pre"],
        indent_body_inner_html: true,
        inline: ["code", "strong", "span", "a"],
    });

    const exportData: string = fillHtmlTemplate(
        findContentTitle(contentValue),
        metaSlug,
        metaDescription,
        contentLanguage,
        formattedHtml);

    triggerFileDownload({
        mime: "text/html",
        fileName: `${sanitizeFilename(metaSlug)}.html`,
        data: exportData,
    });
};

export const exportAsMd = ({metaSlug, metaDescription, contentLanguage, contentValue}: ExportProjectData) => {
    const exportData =
        `[//]: # (Language: ${contentLanguage.value})\n` +
        `[//]: # (Slug: /${metaSlug})\n` +
        `[//]: # (Description: ${metaDescription})\n\n` +
        NodeHtmlMarkdown.translate(
            contentValue,
            {},
            {
                "PRE": {
                    prefix: "```\n",
                    postfix: "```",
                },
            }
        );

    triggerFileDownload({
        mime: "text/markdown",
        fileName: `${sanitizeFilename(metaSlug)}.md`,
        data: exportData,
    });
};

export const triggerFileDownload = ({mime, fileName, data}: File) => {
    const blob = new Blob([data], {type: mime});

    // Create URL for the Blob
    const url = URL.createObjectURL(blob);

    // Create anchor element for downloading
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName; // Suggested file name
    document.body.appendChild(a);
    a.click();

    // Clean up
    URL.revokeObjectURL(url);
    document.body.removeChild(a);
};

const fillHtmlTemplate = (
    title: string,
    metaSlug: string,
    metaDescription: string,
    contentLanguage: LanguageOption,
    formattedHtml: string): string => {
    return (
        "<!DOCTYPE html>\n" +
        `<html lang=\"${contentLanguage.value}\">\n` +
        "<head>\n" +
        "    <meta charset=\"utf-8\"/>\n" +
        `    <!-- Slug: /${metaSlug} -->\n` +
        `    <meta name="description" content="${metaDescription.replace('"', "&quot;")}"/>\n` +
        `    <title>${title}</title>\n` +
        "</head>\n" +
        "<body>\n" +
        formattedHtml +
        "\n" +
        "</body>\n" +
        "</html>"
    );
};