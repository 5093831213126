// https://github.com/Yoast/wordpress-seo/blob/trunk/packages/yoastseo/src/languageProcessing/languages/en/config/twoPartTransitionWords.js

import {CombinationWordsLanguageMap} from "../TransitionWordsChecker";

/**
 * Returns an array with two-part transition words to be used by the assessments.
 * @type {Array} The array filled with two-part transition words.
 */
const twoPartTransitionWords: CombinationWordsLanguageMap = {
    "en-US": [
        ["both", "and"],
        ["if", "then"],
        ["not only", "but also"],
        ["neither", "nor"],
        ["either", "or"],
        ["not", "but"],
        ["whether", "or"],
        ["no sooner", "than"]
    ],
    "nl-NL": [
        ["aan de ene kant", "aan de andere kant"],
        ["enerzijds", "anderzijds"],
        ["natuurlijk", "maar"],
        ["niet alleen", "maar ook"],
        ["noch", "noch"],
        ["zowel", "als"],
    ],
};

export default twoPartTransitionWords;