import React, {useEffect, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {Modal, Table, TableData} from "peggirkit";
import {Keyphrase, KeyphraseLengthScore} from "./KeyphraseLengthChecker";
import ScoreIndicator from "../../../../elements/scoreIndicator/ScoreIndicator";
import {globalModalStyling} from "../../../../elements/modal/modalStyle";

type TableResult = {
    keyphrase: string,
    suggestion: string,
};

type Props = {
    result?: KeyphraseLengthScore
};

const KeyphraseLengthScoreIndicator = ({result}: Props) => {
    const intl = useIntl();
    const [showExplanation, setShowExplanation] = useState(false);
    const [tableData, setTableData] = useState<TableData<TableResult>[]>([]);

    useEffect(() => {
        setTableData(result?.keyphrases.map((keyphrase: Keyphrase) => ({
            keyphrase: keyphrase.keyphrase,
            suggestion: keyphrase.keyphraseSuggestion || "-"
        })) || []);
    }, [result?.keyphrases]);

    if (!result) {
        return <>-</>;
    }

    return (
        <>
            <ScoreIndicator
                score={result.score}
                onClick={() => setShowExplanation(true)}
            />

            <Modal
                {...globalModalStyling(intl)}
                show={showExplanation}
                onClose={() => setShowExplanation(false)}
                title={intl.formatMessage({id: "keyphraseLength"})}
            >
                <div className={"flex flex-col gap-6"}>
                    <p>
                        <FormattedMessage id="keyphraseLength.description-1"/>
                    </p>
                    <p>
                        <FormattedMessage id="keyphraseLength.description-2"/>
                    </p>
                    <Table<TableResult>
                        columns={[
                            {
                                id: "keyphrase",
                                label: {displayName: intl.formatMessage({id: "keyphrase"})},
                            },
                            {
                                id: "suggestion",
                                label: {displayName: intl.formatMessage({id: "suggestion"})},
                            },
                        ]}
                        data={tableData}
                        setData={setTableData}
                    />
                </div>
            </Modal>
        </>
    );
};

export default KeyphraseLengthScoreIndicator;