import React from "react";
import {InternalLinkScore, OutboundLinkScore} from "./links/LinksChecker";
import {useIntl} from "react-intl";
import {DescriptionList, DescriptionListItem, DescriptionListItemActions} from "peggirkit";
import OutboundLinkScoreIndicator from "./links/OutboundLinkScoreIndicator";
import InternalLinkScoreIndicator from "./links/InternalLinkScoreIndicator";
import {LinkFocusScore} from "./linkFocus/LinkFocusChecker";
import LinkFocusScoreIndicator from "./linkFocus/LinkFocusScoreIndicator";
import {KeyphraseInIntroductionScore} from "./keyphraseInIntroduction/KeyphraseInIntroductionChecker";
import KeyphraseInIntroductionScoreIndicator from "./keyphraseInIntroduction/KeyphraseInIntroductionScoreIndicator";
import {KeyphraseDistributionScore} from "./keyphraseDistribution/KeyphraseDistributionChecker";
import KeyphraseDistributionScoreIndicator from "./keyphraseDistribution/KeyphraseDistributionScoreIndicator";
import {markAllKeyphrases, SeoScoresProps} from "../SeoWidget";
import {KeyphraseDensityScore} from "./keyphraseDensity/KeyphraseDensityChecker";
import {KeyphraseLengthScore} from "./keyphraseLength/KeyphraseLengthChecker";
import KeyphraseLengthScoreIndicator from "./keyphraseLength/KeyphraseLengthScoreIndicator";
import KeyphraseInSubheadingScoreIndicator from "./keyphraseInSubheading/KeyphraseInSubheadingScoreIndicator";
import {KeyphraseInSubheadingScore} from "./keyphraseInSubheading/KeyphraseInSubheadingChecker";
import {ContentLengthScore} from "./contentLength/ContentLengthChecker";
import ContentLengthScoreIndicator from "./contentLength/ContentLengthScoreIndicator";
import {useEditor} from "../../../../data/DataProvider";
import {markTexts} from "../../../../editor/marker/Util";
import {generateMarkButton} from "../../readability/ReadabilityScoreList";
import KeyphraseDensityScoreIndicator from "./keyphraseDensity/KeyphraseDensityScoreIndicator";

export type ContentScores = {
    outboundLinks: OutboundLinkScore,
    internalLinks: InternalLinkScore,
    keyphraseLinkFocus: LinkFocusScore,
    keyphraseInIntroduction: KeyphraseInIntroductionScore,
    keyphraseDistribution: KeyphraseDistributionScore,
    keyphraseDensity: KeyphraseDensityScore,
    keyphraseLength: KeyphraseLengthScore,
    keyphraseInSubheading: KeyphraseInSubheadingScore,
    contentLength: ContentLengthScore,
};

type Props = {
    scores?: ContentScores
};

const SeoContentScores = ({scores, seoForm}: SeoScoresProps & Props) => {
    const {setHasMarkedContent, editor} = useEditor();
    const intl = useIntl();

    return (
        <DescriptionList compact={true}>
            <DescriptionListItem
                title={intl.formatMessage({id: "outboundLinks"})}
                content={<OutboundLinkScoreIndicator result={scores?.outboundLinks}/>}
            />
            <DescriptionListItem
                title={intl.formatMessage({id: "internalLinks"})}
                content={<InternalLinkScoreIndicator result={scores?.internalLinks}/>}
            />
            <DescriptionListItem
                title={intl.formatMessage({id: "linkFocus"})}
                content={<LinkFocusScoreIndicator result={scores?.keyphraseLinkFocus}/>}
                actions={
                    <DescriptionListItemActions>
                        {generateMarkButton(() => {
                            scores &&
                            markTexts(setHasMarkedContent, scores.keyphraseLinkFocus.competingLinks.map(l => l.text),
                                editor)
                        }, intl)}
                    </DescriptionListItemActions>
                }
            />
            <DescriptionListItem
                title={intl.formatMessage({id: "keyphraseInIntroduction"})}
                content={<KeyphraseInIntroductionScoreIndicator result={scores?.keyphraseInIntroduction}/>}
            />
            <DescriptionListItem
                title={intl.formatMessage({id: "keyphraseDistribution"})}
                content={<KeyphraseDistributionScoreIndicator result={scores?.keyphraseDistribution}/>}
                actions={
                    <DescriptionListItemActions>
                        {generateMarkButton(() => {
                            markAllKeyphrases(setHasMarkedContent, seoForm, editor)
                        }, intl)}
                    </DescriptionListItemActions>
                }
            />
            <DescriptionListItem
                title={intl.formatMessage({id: "keyphraseDensity"})}
                content={<KeyphraseDensityScoreIndicator result={scores?.keyphraseDensity}/>}
                actions={
                    <DescriptionListItemActions>
                        {generateMarkButton(() => {
                            markAllKeyphrases(setHasMarkedContent, seoForm, editor)
                        }, intl)}
                    </DescriptionListItemActions>
                }
            />
            <DescriptionListItem
                title={intl.formatMessage({id: "keyphraseLength"})}
                content={<KeyphraseLengthScoreIndicator result={scores?.keyphraseLength}/>}
            />
            <DescriptionListItem
                title={intl.formatMessage({id: "contentLength"})}
                content={<ContentLengthScoreIndicator result={scores?.contentLength}/>}
            />
            <DescriptionListItem
                title={intl.formatMessage({id: "keyphraseInSubheading"})}
                content={<KeyphraseInSubheadingScoreIndicator result={scores?.keyphraseInSubheading}/>}
                actions={
                    <DescriptionListItemActions>
                        {generateMarkButton(() => {
                            scores && markTexts(
                                setHasMarkedContent,
                                scores.keyphraseInSubheading.headingsWithKeyphrases.map(l => l.heading),
                                editor)
                        }, intl)}
                    </DescriptionListItemActions>
                }
            />
        </DescriptionList>
    );
};

export default SeoContentScores;