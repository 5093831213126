import React, {ReactElement, useMemo} from "react";
import {WidgetTabPageProps} from "./WidgetTabPage";
import {TabControl, TabControlButton, TabControlPage} from "peggirkit";

export type WidgetTabControlProps = {
    children: ReactElement<WidgetTabPageProps> | ReactElement<WidgetTabPageProps>[],
};

const WidgetTabControl = ({children}: WidgetTabControlProps) => {
    const renderedPages = useMemo(() => React.Children.map(children, (c) =>
        <TabControlPage
            tabButton={
                <TabControlButton>
                    {c.props.name}
                </TabControlButton>
            }
            unmount={false}
        >
            {c.props.children}
        </TabControlPage>
    ), [children]);

    return (
        <TabControl
            tabPanelsContainerClassname={"mt-3"}
            tabsListStyle={{
                type: "button-row",
                style: {
                    containerClassName: "flex flex-row items-center gap-2",
                    button: {
                        size: "xs",
                        color: "secondary",
                        variant: "solid"
                    },
                },
            }}
        >
            {renderedPages}
        </TabControl>
    );
};

export default WidgetTabControl;