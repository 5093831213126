import React, {useEffect} from "react";
import {ActionRowButton, Button, Icon, NotificationCardActions, useNotifications} from "peggirkit";
import {InformationCircleIcon} from "@heroicons/react/24/outline";
import {FormattedMessage, IntlShape} from "react-intl";
import {SetShowFn} from "../../about/AboutModal";

const aboutButton = (intl: IntlShape, setShowInfoModal: SetShowFn) => {
    const {notify} = useNotifications();

    useEffect(() => {
        const timeout = setTimeout(() => {
            notify({
                duration: 10000,
                icon: <Icon icon={InformationCircleIcon}/>,
                message: intl.formatMessage({id: "welcomeNotification.title"}),
                description: intl.formatMessage({id: "welcomeNotification.description"}),
                actions: (
                    <NotificationCardActions>
                        <Button
                            color={"secondary"}
                            buttonAction={{
                                as: "button",
                                actionProps: {onClick: () => setShowInfoModal(true)}
                            }}
                        >
                            <FormattedMessage id={"about"}/>
                        </Button>
                    </NotificationCardActions>
                ),
            });
        }, 1000);
        return () => clearTimeout(timeout);
    }, []);

    return (
        <ActionRowButton
            showInRowBreakpoint={"lg"}
            buttonClassname={"-mr-2"}
            buttonAction={{
                as: "button",
                actionProps: {onClick: () => setShowInfoModal(true)}
            }}
            iconBefore={<Icon icon={InformationCircleIcon}/>}
            color={"secondary"}
            variant={"text"}
            borderRadius={"rounded-full"}
            iconOnly={true}
            title={intl.formatMessage({id: "about"})}
        >
            <FormattedMessage id={"about"}/>
        </ActionRowButton>
    );
}

export default aboutButton;