import nlp from "compromise";
import * as cheerio from "cheerio";
import {KeyphrasesForm} from "../../SeoWidget";
import {GeneralScore} from "../../../../elements/scoreIndicator/ScoreIndicator";

// Based on https://yoast.com/focus-keyphrase-in-introduction/#write-good-introductions

export type KeyphraseInIntroductionScore = {
    score: GeneralScore,
    focusKeyphrasePresent: boolean,
    focusKeyphraseInFirstSentence: boolean,
    synonymPresent: boolean,
};

export const checkKeyphraseInIntroduction = (htmlContent: string, form: KeyphrasesForm): KeyphraseInIntroductionScore => {
    const $ = cheerio.load(htmlContent);
    const firstParagraph = $("p").first().text();

    const doc = nlp(firstParagraph);
    const focusKeyphrasePresent: boolean = doc.has(form.keyphrase);
    const focusKeyphraseInFirstSentence: boolean = doc.sentences(0).has(form.keyphrase);
    const synonymPresent: boolean = form.keyphraseSynonyms.filter(synonym => doc.has(synonym)).length > 0;

    return {
        score: focusKeyphraseInFirstSentence
            ? "good"
            : (focusKeyphrasePresent || synonymPresent ? "okay" : "bad"),
        focusKeyphrasePresent: focusKeyphrasePresent,
        focusKeyphraseInFirstSentence: focusKeyphraseInFirstSentence,
        synonymPresent: synonymPresent,
    };
};