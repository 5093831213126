import React from "react";
import {globalModalResponsiveStyling} from "../widgets/elements/modal/modalStyle";
import {Button, NotifyModal, TabControl, TabControlButton, TabControlPage} from "peggirkit";
import {FormattedMessage, useIntl} from "react-intl";

export type SetShowFn = React.Dispatch<React.SetStateAction<boolean>>;

type Props = {
    show: boolean,
    setShow: SetShowFn,
};

const AboutModal = ({show, setShow}: Props) => {
    const intl = useIntl();

    return (
        <NotifyModal
            {...globalModalResponsiveStyling}
            type={"info"}
            show={show}
            onClose={() => setShow(false)}
            title={intl.formatMessage({id: "aboutPeggirSeoEditor"})}
            dismissButton={{text: intl.formatMessage({id: "close"})}}
        >
            <TabControl tabPanelsContainerClassname={"pt-6 text-left"}>
                <TabControlPage
                    tabButton={
                        <TabControlButton>
                            {intl.formatMessage({id: "aboutPeggirSeoEditor.general"})}
                        </TabControlButton>
                    }
                >
                    <div className={"flex flex-col gap-6"}>
                        <p>
                            <FormattedMessage id={"aboutPeggirSeoEditor.general.p-1"}/>
                        </p>
                        <p>
                            <FormattedMessage id={"aboutPeggirSeoEditor.general.p-2"}/>
                        </p>
                        <p>
                            <FormattedMessage id={"aboutPeggirSeoEditor.general.p-3"}/>
                        </p>

                        <Button
                            variant={"link"}
                            buttonAction={{
                                as: "a",
                                actionProps: {
                                    href: "https://peggir.com/",
                                    target: "_blank",
                                    rel: "nofollow"
                                },
                            }}
                        >
                            <FormattedMessage id={"aboutPeggirSeoEditor.general.p-4"}/>
                        </Button>
                    </div>
                </TabControlPage>
                <TabControlPage
                    tabButton={
                        <TabControlButton>
                            {intl.formatMessage({id: "aboutPeggirSeoEditor.privacy"})}
                        </TabControlButton>
                    }
                >
                    <div className={"flex flex-col gap-6"}>
                        <p>
                            <FormattedMessage id={"aboutPeggirSeoEditor.privacy.p-1"}/>
                        </p>
                        <p>
                            <FormattedMessage id={"aboutPeggirSeoEditor.privacy.p-2"}/>
                        </p>
                    </div>
                </TabControlPage>
            </TabControl>
        </NotifyModal>
    );
};

export default AboutModal;