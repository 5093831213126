import React, {ReactNode, useMemo} from "react";
import {useIntl} from "react-intl";
import {CheckCircleIcon, ExclamationCircleIcon, MinusCircleIcon} from "@heroicons/react/24/outline";
import {Button, classNames} from "peggirkit";

export type GeneralScore = "good" | "okay" | "bad" | null;

const iconClassname = "w-5 h-5 shrink-0 grow-0";

type Props = {
    score: GeneralScore,
    onClick: () => void
};

const ScoreIndicator = ({score, onClick}: Props) => {
    const intl = useIntl();
    const icon: ReactNode = useMemo(() => {
        switch (score) {
            case "good":
                return <CheckCircleIcon
                    className={classNames(
                        iconClassname,
                        "text-green-700 dark:text-green-400")
                    }
                />;
            case "okay":
                return <MinusCircleIcon
                    className={classNames(
                        iconClassname,
                        "text-orange-400 dark:text-orange-300"
                    )}
                />;
            case "bad":
                return <ExclamationCircleIcon
                    className={classNames(
                        iconClassname,
                        "text-red-700 dark:text-red-400"
                    )}
                />;
        }

        return <></>;
    }, [score]);

    return (
        <div className={"flex flex-row items-center justify-start gap-1"}>
            {icon}

            <Button
                variant={"link"}
                buttonAction={{
                    as: "button",
                    actionProps: {onClick},
                }}
            >
                {
                    score === null
                        ? "-"
                        : intl.formatMessage({id: score})
                }
            </Button>
        </div>
    );
};

export default ScoreIndicator;