import React from "react";
import {Button, classNames, Icon} from "peggirkit";
import {EyeSlashIcon} from "@heroicons/react/24/outline";
import {FormattedMessage, useIntl} from "react-intl";
import {unmarkText} from "./Util";
import {useEditor} from "../../data/DataProvider";

const RemoveMarkerButton = () => {
    const intl = useIntl();
    const {editor, hasMarkedContent, setHasMarkedContent} = useEditor();

    return (
        <Button
            variant={"solid"}
            borderRadius={"rounded-full"}
            color={"secondary"}
            buttonClassname={classNames(
                "!sticky z-10 shadow top-0 left-[100%] transition-opacity",
                !hasMarkedContent ? "pointer-events-none opacity-0" : "opacity-100",
            )}
            iconOnly={true}
            iconBefore={<Icon icon={EyeSlashIcon} className={"text-yellow-500"}/>}
            buttonAction={{
                as: "button",
                actionProps: {
                    onClick: () => editor && unmarkText(editor, setHasMarkedContent)
                }
            }}
            title={intl.formatMessage({id: "removeHighlights"})}
        >
            <FormattedMessage id={"removeHighlights"}/>
        </Button>
    );
};

export default RemoveMarkerButton;