import React from "react";
import {Form, TextField} from "peggirkit";
import {useIntl} from "react-intl";
import {useEditor} from "../../../data/DataProvider";
import {seoWidgetId} from "./SeoWidget";

const KeyphrasesInput = () => {
    const intl = useIntl();
    const {widgetData, setWidgetData} = useEditor();

    return (
        <Form containerClassname={"mb-6 !gap-2"}>
            <TextField
                type={"text"}
                title={intl.formatMessage({id: "keyphrase"})}
                placeholder={intl.formatMessage({id: "keyphrase"})}
                value={widgetData[seoWidgetId]?.keyphrase || ""}
                onChange={e => setWidgetData(prev => ({
                    ...prev,
                    [seoWidgetId]: {
                        ...prev[seoWidgetId],
                        keyphrase: e.target.value,
                    },
                }))}
                error={widgetData[seoWidgetId]?.keyphraseValidationError}
            />
            <TextField
                type={"text"}
                title={intl.formatMessage({id: "keyphraseSynonymsCommaSeparated"})}
                placeholder={intl.formatMessage({id: "keyphraseSynonymsCommaSeparated"})}
                value={widgetData[seoWidgetId]?.keyphraseSynonyms || ""}
                onChange={e => setWidgetData(prev => ({
                    ...prev,
                    [seoWidgetId]: {
                        ...prev[seoWidgetId],
                        keyphraseSynonyms: e.target.value,
                    },
                }))}
            />
        </Form>
    );
};

export default KeyphrasesInput;