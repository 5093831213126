import React, {ChangeEvent, useRef, useState} from "react";
import {ActionRowButton, ActionRowButtonProps, ConfirmModal, Icon} from "peggirkit";
import {FolderOpenIcon} from "@heroicons/react/24/outline";
import {FormattedMessage, useIntl} from "react-intl";
import {buttonStyling} from "./projectButtons";
import {fileExtension, ProjectFileData} from "../../../data/project";
import {globalModalResponsiveStyling} from "../../../widgets/elements/modal/modalStyle";
import {useEditor} from "../../../data/DataProvider";

const LoadProjectButton = (props: Partial<ActionRowButtonProps>) => {
    const intl = useIntl();
    const [showModal, setShowModal] = useState(false);
    const {loadProject} = useEditor();
    const uploaderRef = useRef<HTMLInputElement | null>(null);

    const handleLoadProjectFile = (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();

        if (!e.target.files || e.target.files.length === 0) {
            setShowModal(false);
            return;
        }

        const reader = new FileReader();
        reader.onload = (e) => {
            if (!e.target) {
                setShowModal(false);
                return;
            }

            const projectData: ProjectFileData = JSON.parse(e.target.result as string);
            loadProject(projectData);
            setShowModal(false);
        };

        reader.readAsText(e.target.files[0]);
    };

    return (
        <>
            <ActionRowButton
                {...props}
                {...buttonStyling}
                iconBefore={<Icon icon={FolderOpenIcon}/>}
                buttonAction={{as: "button", actionProps: {onClick: () => setShowModal(true)}}}
                title={intl.formatMessage({id: "load"})}
            >
                <FormattedMessage id={"load"}/>
            </ActionRowButton>

            <ConfirmModal
                {...globalModalResponsiveStyling}
                type={"warning"}
                loading={false}
                show={showModal}
                onClose={() => setShowModal(false)}
                title={intl.formatMessage({id: "discardCurrentProject"})}
                dismissButton={{
                    text: intl.formatMessage({id: "cancel"}),
                }}
                continueButton={{
                    text: intl.formatMessage({id: "continue"}),
                    onClick: () => {
                        uploaderRef.current?.click();
                    },
                }}
            >
                <FormattedMessage id={"discardCurrentProject.description"}/>

                <input
                    hidden
                    accept={fileExtension}
                    type="file"
                    multiple={false}
                    onChange={handleLoadProjectFile}
                    ref={uploaderRef}
                />
            </ConfirmModal>
        </>
    );
};

export default LoadProjectButton;