import React from "react";
import {Button, DescriptionList, DescriptionListItem, DescriptionListItemActions, Icon} from "peggirkit";
import {FormattedMessage, IntlShape, useIntl} from "react-intl";
import {ReadabilityScoresProps} from "./ReadabilityWidget";
import ParagraphLengthScoreIndicator from "./paragraphLength/ParagraphLengthScoreIndicator";
import {markTexts, unmarkText} from "../../../editor/marker/Util";
import {useEditor} from "../../../data/DataProvider";
import SubheadingDistributionScoreIndicator from "./subheadingDistribution/SubheadingDistributionScoreIndicator";
import TransitionWordsScoreIndicator from "./transitionWords/TransitionWordsScoreIndicator";
import ConsecutiveSentencesScoreIndicator from "./consecutiveSentences/ConsecutiveSentencesScoreIndicator";
import SentenceLengthScoreIndicator from "./sentenceLength/sentenceLengthScoreIndicator";
import PassiveVoiceScoreIndicator from "./passiveVoice/PassiveVoiceScoreIndicator";
import {EyeIcon} from "@heroicons/react/24/outline";

type Props = {
    score?: ReadabilityScoresProps,
    withMarkButtons?: boolean,
};

export const generateMarkButton = (onClick: () => void, intl: IntlShape) => (
    <Button
        variant={"text"}
        color={"secondary"}
        borderRadius={"rounded-full"}
        iconBefore={<Icon icon={EyeIcon}/>}
        iconOnly={true}
        title={intl.formatMessage({id: "highlight"})}
        buttonAction={{
            as: "button",
            actionProps: {onClick}
        }}
    >
        <FormattedMessage id={"highlight"}/>
    </Button>
);

const ReadabilityScoreList = ({score, withMarkButtons = true}: Props) => {
    const intl = useIntl();
    const {editor, setHasMarkedContent} = useEditor();

    return (
        <DescriptionList compact={true}>
            <DescriptionListItem
                title={intl.formatMessage({id: "paragraphLength"})}
                content={<ParagraphLengthScoreIndicator result={score?.paragraphLength}/>}
                actions={!withMarkButtons ? undefined :
                    <DescriptionListItemActions>
                        {generateMarkButton(() => {
                            markTexts(setHasMarkedContent, score?.paragraphLength.longParagraphs.map(p => p.text), editor);
                        }, intl)}
                    </DescriptionListItemActions>
                }
            />

            <DescriptionListItem
                title={intl.formatMessage({id: "sentenceLength"})}
                content={<SentenceLengthScoreIndicator result={score?.sentenceLength}/>}
                actions={!withMarkButtons ? undefined :
                    <DescriptionListItemActions>
                        {generateMarkButton(() => {
                            markTexts(setHasMarkedContent, score?.sentenceLength.longSentences.map(s => s.text), editor);
                        }, intl)}
                    </DescriptionListItemActions>
                }
            />

            <DescriptionListItem
                title={intl.formatMessage({id: "passiveVoice"})}
                content={<PassiveVoiceScoreIndicator result={score?.passiveVoice}/>}
                actions={!withMarkButtons ? undefined :
                    <DescriptionListItemActions>
                        {generateMarkButton(() => {
                            markTexts(setHasMarkedContent, score?.passiveVoice.passiveSentences.map(s => s.text), editor);
                        }, intl)}
                    </DescriptionListItemActions>
                }
            />

            <DescriptionListItem
                title={intl.formatMessage({id: "consecutiveSentences"})}
                content={<ConsecutiveSentencesScoreIndicator result={score?.consecutiveSentences}/>}
                actions={!editor || !withMarkButtons ? undefined :
                    <DescriptionListItemActions>
                        {generateMarkButton(() => {
                            unmarkText(editor, setHasMarkedContent);
                            score?.consecutiveSentences.consecutiveSentences
                                .map((s: string[]) => markTexts(setHasMarkedContent, s, editor, false));
                        }, intl)}
                    </DescriptionListItemActions>
                }
            />

            <DescriptionListItem
                title={intl.formatMessage({id: "subheadingDistribution"})}
                content={<SubheadingDistributionScoreIndicator result={score?.subheadingDistribution}/>}
            />

            <DescriptionListItem
                title={intl.formatMessage({id: "transitionWords"})}
                content={<TransitionWordsScoreIndicator result={score?.transitionWords}/>}
                actions={!withMarkButtons ? undefined :
                    <DescriptionListItemActions>
                        {generateMarkButton(() => {
                            markTexts(setHasMarkedContent, score?.transitionWords?.sentencesWithTransition, editor);
                        }, intl)}
                    </DescriptionListItemActions>
                }
            />
        </DescriptionList>
    );
};

export default ReadabilityScoreList;