import React, {useState} from "react";
import {SentenceLengthScore} from "./sentenceLengthChecker";
import {FormattedMessage, useIntl} from "react-intl";
import {Modal} from "peggirkit";
import ScoreIndicator from "../../../elements/scoreIndicator/ScoreIndicator";
import {globalModalStyling} from "../../../elements/modal/modalStyle";

type Props = {
    result?: SentenceLengthScore
};

const SentenceLengthScoreIndicator = ({result}: Props) => {
    const intl = useIntl();
    const [showExplanation, setShowExplanation] = useState(false);

    if (!result) {
        return <>-</>;
    }

    return (
        <>
            <ScoreIndicator
                score={result.score}
                onClick={() => setShowExplanation(true)}
            />

            <Modal
                {...globalModalStyling(intl)}
                show={showExplanation}
                onClose={() => setShowExplanation(false)}
                title={intl.formatMessage({id: "sentenceLengths"})}
            >
                <div className={"flex flex-col gap-6"}>
                    <p>
                        <FormattedMessage id="sentenceLengths.description-1"/>
                    </p>
                    <p>
                        <span className={"text-black dark:text-white font-medium"}>
                            <FormattedMessage
                                id="sentenceLengths.description-2"
                                values={{
                                    longSentences: result.longSentences.length,
                                    sentenceCount: result.sentenceCount,
                                    longSentencesPercentage: result.longSentencesPercentage,
                                }}
                            />
                        </span>
                    </p>
                </div>
            </Modal>
        </>
    );
};

export default SentenceLengthScoreIndicator;