import {LanguageOption, WidgetData} from "./DataProvider";
import {triggerFileDownload} from "./export";
import {isEmpty} from "peggirkit";

export const localCookieName = "peggir-seo-editor-autosave";

export type ProjectFileData = {
    contentLanguage: LanguageOption,
    metaSlug: string,
    metaDescription: string,
    contentValue: string,
    hasMarkedContent: boolean,
    widgetData: WidgetData,
};

export type LoadProjectFn = (projectData: ProjectFileData) => void;

export type ClearProjectFn = () => void;
export type AutoSaveFn = () => void;

export const fileExtension = ".pegseo";

export const saveProjectFile = (fileName: string, data: ProjectFileData) => {
    const stringData: string = JSON.stringify(data);
    triggerFileDownload({
        mime: "application/json",
        fileName: `${fileName}${fileExtension}`,
        data: stringData,
    });
};

export const saveLocally = (data: ProjectFileData) => {
    const stringData: string = JSON.stringify(data);
    localStorage.setItem(localCookieName, stringData);
};

export const getLocalProject = (): ProjectFileData | null => {
    const fromLocalStorage = localStorage.getItem(localCookieName);
    if (!fromLocalStorage || isEmpty(fromLocalStorage)) {
        return null;
    }

    return JSON.parse(fromLocalStorage) as ProjectFileData;
};

export const sanitizeFilename = (filename: string) => {
    // Replace spaces with dashes
    filename = filename.replace(/\s+/g, '-');

    // Remove characters not allowed in filenames
    filename = filename.replace(/[^a-zA-Z0-9-_.]/g, '');

    return filename.toLowerCase().trim();
}