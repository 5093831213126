import React, {ReactNode} from "react";

type Props = {
    children: ReactNode | string,
}

const FootNote = ({children}: Props) => {
    return (
        <p className={"w-full mt-2 text-right text-gray-600 dark:text-slate-400 text-xs"}>
            {children}
        </p>
    );
};

export default FootNote;