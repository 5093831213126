import React, {useEffect, useMemo} from "react";
import {useIntl} from "react-intl";
import Layout from "../layout/Layout";
import {DataProvider} from "../data/DataProvider";
import {getLocalProject, ProjectFileData} from "../data/project";


const Main = () => {
    const intl = useIntl();
    const localProject: ProjectFileData | null = useMemo(() => getLocalProject(), []);

    useEffect(() => {
        document.title = window.matchMedia('(display-mode: standalone)').matches
            ? intl.formatMessage({id: "edit"})
            : `${intl.formatMessage({id: "edit"})} - ${intl.formatMessage({id: "appName"})}`;
    }, []);

    return (
        <DataProvider initialProject={localProject}>
            <Layout/>
        </DataProvider>
    );
};

export default Main;