import "./quill.bubble.custom.css";
import React, {useMemo} from "react";
import ReactQuill from "react-quill";
import Quill, {StringMap} from "quill";
import {useEditor} from "../data/DataProvider";
import {useIntl} from "react-intl";
import MarkerBlot from "./marker/MarkerBlot";

const icons = Quill.import("ui/icons");
icons["code-block"] = '<svg viewbox="0 -2 15 18">' +
    '<polyline class="ql-even ql-stroke" points="2.48 2.48 1 3.96 2.48 5.45"/>' +
    '<polyline class="ql-even ql-stroke" points="8.41 2.48 9.9 3.96 8.41 5.45"/>' +
    '<line class="ql-stroke" x1="6.19" y1="1" x2="4.71" y2="6.93"/>' +
    '<polyline class="ql-stroke" points="12.84 3 14 3 14 13 2 13 2 8.43"/>' +
    '</svg>';

const Editor = () => {
    const intl = useIntl();
    const {setEditor, contentValue, setContentValue} = useEditor();

    const initialQuillConfig: StringMap = useMemo(() => {
        Quill.register("modules/global", (quill: Quill) => {
            setEditor(quill);
        });

        return {global: true};
    }, [setEditor]);

    return (
        <div className={"quill-bounds-container w-full max-w-[700px] mx-auto flex flex-col gap-6"}>
            <ReactQuill
                theme="bubble"
                value={contentValue}
                onChange={setContentValue}
                className={"w-full"}
                placeholder={intl.formatMessage({id: "startTyping"})}
                scrollingContainer={".scroll-container"}
                modules={{
                    ...initialQuillConfig,
                    toolbar: [
                        [{header: [1, 2, 3, 4, false]}],
                        ["bold", "italic", "underline"],
                        ["link"],
                        [{"list": "ordered"}, {"list": "bullet"}],
                        ["code", "code-block"],
                    ],
                    clipboard: {
                        matchVisual: false
                    }
                }}
                bounds={".quill-bounds-container"}
                formats={[
                    "header", "bold", "italic", "underline", "link", "list", "code", "code-block",
                    MarkerBlot.blotName,
                ]}
            />
        </div>
    );
};

export default Editor;