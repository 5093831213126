import nlp from "compromise";
import {GeneralScore} from "../../../elements/scoreIndicator/ScoreIndicator";

// Based on: https://yoast.com/paragraph-length-check/

export type LongParagraph = {
    text: string,
    wordCount: number,
};

export type ParagraphLengthScore = {
    score: GeneralScore
    longParagraphs: LongParagraph[],
};

export const checkParagraphLengths = (text: string): ParagraphLengthScore => {
    const longParagraphs: LongParagraph[] = [];
    let longestWordCount = 0;
    text
        .split("\n")
        .map(p => {
            const wordCount = nlp(p).wordCount();
            if (wordCount >= 150) {
                if (wordCount > longestWordCount) {
                    longestWordCount = wordCount;
                }

                longParagraphs.push({
                    text: p,
                    wordCount: wordCount
                });
            }
        });

    return {
        score: longestWordCount >= 200 ? "bad" : (longestWordCount >= 150 ? "okay" : "good"),
        longParagraphs: longParagraphs,
    };
};