import React, {ReactNode, useEffect, useState} from "react";
import {ConsecutiveSentencesScore} from "./ConsecutiveSentencesChecker";
import {FormattedMessage, useIntl} from "react-intl";
import {Modal, Table, TableData} from "peggirkit";
import ScoreIndicator from "../../../elements/scoreIndicator/ScoreIndicator";
import {globalModalStyling} from "../../../elements/modal/modalStyle";

type Result = {
    sentences: ReactNode,
};

type Props = {
    result?: ConsecutiveSentencesScore
};

const ConsecutiveSentencesScoreIndicator = ({result}: Props) => {
    const intl = useIntl();
    const [showExplanation, setShowExplanation] = useState(false);
    const [results, setResults] = useState<TableData<Result>[]>([]);

    useEffect(() => {
        setResults(
            result?.consecutiveSentences.map((sentences: string[]) => ({
                sentences: (
                    <ul className={"list-disc list-inside block whitespace-normal"}>
                        {sentences.map((s, i) => <li key={i}>{s}</li>)}
                    </ul>
                )
            })) || []);
    }, [result?.consecutiveSentences]);

    if (!result) {
        return <>-</>;
    }

    return (
        <>
            <ScoreIndicator
                score={result.score}
                onClick={() => setShowExplanation(true)}
            />

            <Modal
                {...globalModalStyling(intl)}
                show={showExplanation}
                onClose={() => setShowExplanation(false)}
                title={intl.formatMessage({id: "consecutiveSentences"})}
            >
                <div className={"flex flex-col gap-6"}>
                    <p>
                        <FormattedMessage id="consecutiveSentences.description-1"/>
                    </p>
                    <p>
                        <span className={"text-black dark:text-white font-medium"}>
                            <FormattedMessage
                                id="consecutiveSentences.description-2"
                                values={{
                                    sentenceCount: result.sentenceCount,
                                    numOfConsecutiveSentences: result.consecutiveSentences.length,
                                    longestStreak: result.longestStreak,
                                }}
                            />
                        </span>
                    </p>

                    <Table<Result>
                        columns={[{
                            id: "sentences",
                            label: {displayName: intl.formatMessage({id: "sentences"})}
                        }]}
                        data={results}
                        setData={setResults}
                    />
                </div>
            </Modal>
        </>
    );
};

export default ConsecutiveSentencesScoreIndicator;