import * as cheerio from "cheerio";
import nlp from "compromise";
import {GeneralScore} from "../../../../elements/scoreIndicator/ScoreIndicator";

// Based on https://yoast.com/outbound-links/ & https://yoast.com/internal-linking-for-seo-why-and-how/

export type Link = {
    text: string,
    href: string,
};

export type OutboundLinkScore = {
    score: GeneralScore,
    links: Link[]
};

export type InternalLinkScore = {
    score: GeneralScore,
    links: Link[],
    wordCount: number,
    linksRecommended: number,
};

export type LinkScore = {
    outboundScore: OutboundLinkScore
    internalScore: InternalLinkScore
};

const isOutboundUrl = (url: string): boolean => {
    return (/^https?:/).test(url);
}

export const checkLinks = (htmlContent: string, textContent: string): LinkScore => {
    const $ = cheerio.load(htmlContent);
    const outboundLinks: Link[] = [];
    const internalLinks: Link[] = [];

    $("a").each((i, e) => {
        const el = $(e);
        const text: string = el.text();
        const href: (string | undefined) = el.prop("href");
        const link: Link = {
            text: text,
            href: href || ""
        };
        href && isOutboundUrl(href)
            ? outboundLinks.push(link)
            : internalLinks.push(link);
    });

    // Internal link score: Based on 5 links per 2000 words
    const doc = nlp(textContent);
    const wordCount = doc.wordCount();
    const internalLinksNeeded = Math.round((5 / 2000) * wordCount);

    return {
        outboundScore: {
            score: outboundLinks.length > 0 ? "good" : "bad",
            links: outboundLinks
        },
        internalScore: {
            score: internalLinks.length === 0
                ? "bad"
                : (internalLinks.length >= internalLinksNeeded ? "good" : "okay"),
            links: internalLinks,
            wordCount: wordCount,
            linksRecommended: internalLinksNeeded
        },
    };
};