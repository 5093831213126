import nlp from "compromise";
import {GeneralScore} from "../../../../elements/scoreIndicator/ScoreIndicator";

// Based on https://yoast.com/meta-descriptions/#yoast-seo-meta-description

export type ContentLengthScore = {
    score: GeneralScore,
    wordCount: number,
};

export const checkContentLength = (content: string): ContentLengthScore => {
    const doc = nlp(content);
    const wordCount = doc.wordCount();

    return {
        score: wordCount >= 300
            ? "good"
            : (wordCount >= 200 ? "okay" : "bad"),
        wordCount: wordCount
    };
};