import React, {useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {Modal} from "peggirkit";
import {KeyphraseInTitleScore} from "./KeyphraseInTitleChecker";
import ScoreIndicator from "../../../../elements/scoreIndicator/ScoreIndicator";
import {globalModalStyling} from "../../../../elements/modal/modalStyle";

type Props = {
    result?: KeyphraseInTitleScore
};

const KeyphraseInTitleScoreIndicator = ({result}: Props) => {
    const intl = useIntl();
    const [showExplanation, setShowExplanation] = useState(false);

    if (!result) {
        return <>-</>;
    }

    return (
        <>
            <ScoreIndicator
                score={result.score}
                onClick={() => setShowExplanation(true)}
            />

            <Modal
                {...globalModalStyling(intl)}
                show={showExplanation}
                onClose={() => setShowExplanation(false)}
                title={intl.formatMessage({id: "keyphraseInTitle"})}
            >
                <div className={"flex flex-col gap-6"}>
                    <p>
                        <FormattedMessage id="keyphraseInTitle.description-1"/>
                    </p>
                    <p>
                        <FormattedMessage id="keyphraseInTitle.description-2"/>
                    </p>
                    <p>
                        <FormattedMessage id="keyphraseInTitle.description-3"/>
                    </p>
                </div>
            </Modal>
        </>
    );
};

export default KeyphraseInTitleScoreIndicator;