import {KeyphrasesForm} from "../../SeoWidget";
import nlp from "compromise";
import {GeneralScore} from "../../../../elements/scoreIndicator/ScoreIndicator";

// Based on https://yoast.com/keyphrase-distribution-what-it-is-and-how-to-balance-it/

export type KeyphraseDensityScore = {
    score: GeneralScore,
    wordCount: number,
    keyphraseWordCount: number,
    densityPercentage: number,
};

export const checkKeyphraseDensity = (content: string, form: KeyphrasesForm): KeyphraseDensityScore => {
    const doc = nlp(content);
    const wordCount = doc.wordCount();
    const keyphraseTerms = nlp(form.keyphrase).termList();

    let keyphraseWordCount = 0;
    doc.fullSentences().map((sentence: any) => {
        let isMatch: boolean = true;
        keyphraseTerms.forEach((term: any) => isMatch = isMatch && sentence.has(term.text))

        if (isMatch) {
            keyphraseWordCount += keyphraseTerms.length;
        }
    })

    const densityPercentage = (keyphraseWordCount / wordCount) * 100;
    const roundedDensityPercentage = Math.round(densityPercentage * 100) / 100;

    return {
        score: densityPercentage >= 0.5 && densityPercentage <= 3 ? "good" : "bad",
        wordCount: wordCount,
        keyphraseWordCount: keyphraseWordCount,
        densityPercentage: roundedDensityPercentage,
    };
};