import React from "react";
import {ActionRow, ActionRowDropdownButton, ActionRowMoreButton, DropdownItem, Icon, TopBar} from "peggirkit";
import {CodeBracketIcon, EllipsisVerticalIcon, HashtagIcon, ShareIcon} from "@heroicons/react/24/outline";
import {FormattedMessage, useIntl} from "react-intl";
import ToolbarLogo from "./logo/ToolbarLogo";
import NewProjectButton from "./actions/project/NewProjectButton";
import LoadProjectButton from "./actions/project/LoadProjectButton";
import SaveProjectButton from "./actions/project/SaveProjectButton";
import ToggleWidgetsSliderButton, {ToggleWidgetsSliderButtonProps} from "./actions/ToggleWidgetsSliderButton";
import {LanguageOption, useEditor} from "../data/DataProvider";
import {unmarkText} from "../editor/marker/Util";
import {exportAsHtml, exportAsMd} from "../data/export";
import aboutButton from "./actions/AboutButton";
import autoSaveButton from "./actions/AutoSaveButton";
import {SetShowFn} from "../about/AboutModal";

type Props = ToggleWidgetsSliderButtonProps & {
    setShowInfoModal: SetShowFn,
};

const Toolbar = ({setShowWidgetsSlider, setShowInfoModal}: Props) => {
    const intl = useIntl();
    const {metaSlug, metaDescription, contentLanguage, contentValue, editor, autoSavedOn, autoSave} = useEditor();

    return (
        <TopBar
            containerClassname={"py-2 select-none"}
            logo={<ToolbarLogo/>}
            startActionsStylingType={"button"}
            startActions={[
                <NewProjectButton/>,
                <LoadProjectButton/>,
                <SaveProjectButton/>,
            ]}
            endActions={
                <ActionRow
                    useMoreButton={true}
                    moreButton={
                        <ActionRowMoreButton
                            color={"secondary"}
                            variant={"text"}
                            buttonClassname={"-mr-2"}
                            borderRadius={"rounded-full"}
                            iconOnly={true}
                            iconBefore={<Icon icon={EllipsisVerticalIcon}/>}
                            hideChevron={true}
                            title={intl.formatMessage({id: "options"})}
                        />
                    }
                    moreButtonPositioning={"end"}
                >
                    {autoSaveButton(autoSave, autoSavedOn)}

                    <ToggleWidgetsSliderButton setShowWidgetsSlider={setShowWidgetsSlider}/>

                    <ActionRowDropdownButton
                        showInRowBreakpoint={"md"}
                        iconBefore={<Icon icon={ShareIcon}/>}
                        color={"secondary"}
                        variant={"text"}
                        items={[[
                            <DropdownItem
                                icon={<Icon icon={CodeBracketIcon}/>}
                                buttonAction={{
                                    as: "button",
                                    actionProps: {
                                        onClick: () => {
                                            editor && unmarkText(editor);
                                            exportAsHtml({
                                                contentLanguage: contentLanguage as LanguageOption,
                                                contentValue,
                                                metaSlug,
                                                metaDescription,
                                            });
                                        }
                                    }
                                }}
                            >
                                <FormattedMessage id={"exportToHtml"}/>
                            </DropdownItem>,
                            <DropdownItem
                                icon={<Icon icon={HashtagIcon}/>}
                                buttonAction={{
                                    as: "button",
                                    actionProps: {
                                        onClick: () => {
                                            editor && unmarkText(editor);
                                            exportAsMd({
                                                contentLanguage: contentLanguage as LanguageOption,
                                                contentValue,
                                                metaSlug,
                                                metaDescription,
                                            });
                                        }
                                    }
                                }}
                            >
                                <FormattedMessage id={"exportToMarkdown"}/>
                            </DropdownItem>,
                        ]]}
                    >
                        <FormattedMessage id={"share"}/>
                    </ActionRowDropdownButton>

                    {aboutButton(intl, setShowInfoModal)}
                </ActionRow>
            }
        />
    );
};

export default Toolbar;