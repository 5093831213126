import React, {ReactNode, useEffect, useState} from "react";
import {PassiveVoiceScore} from "./PassiveVoiceChecker";
import {FormattedMessage, useIntl} from "react-intl";
import {Modal, Table, TableData} from "peggirkit";
import ScoreIndicator from "../../../elements/scoreIndicator/ScoreIndicator";
import {globalModalStyling} from "../../../elements/modal/modalStyle";

type Result = {
    sentence: ReactNode,
};

type Props = {
    result?: PassiveVoiceScore
};

const PassiveVoiceScoreIndicator = ({result}: Props) => {
    const intl = useIntl();
    const [showExplanation, setShowExplanation] = useState(false);
    const [results, setResults] = useState<TableData<Result>[]>([]);

    useEffect(() => {
        setResults(
            result?.passiveSentences.map(({text}) => ({
                sentence: (
                    <span className={"block whitespace-normal"}>
                    {text}
                </span>
                )
            })) || []);
    }, [result?.passiveSentences]);

    if (!result) {
        return <>-</>;
    }

    return (
        <>
            <ScoreIndicator
                score={result.score}
                onClick={() => setShowExplanation(true)}
            />

            <Modal
                {...globalModalStyling(intl)}
                show={showExplanation}
                onClose={() => setShowExplanation(false)}
                title={intl.formatMessage({id: "passiveSentences"})}
            >
                <div className={"flex flex-col gap-6"}>
                    <p>
                        <FormattedMessage id="passiveSentences.description-1"/>
                    </p>
                    <p>
                        <span className={"text-black dark:text-white font-medium"}>
                            <FormattedMessage
                                id="passiveSentences.description-2"
                                values={{
                                    passiveSentences: result.passiveSentences.length,
                                    sentenceCount: result.sentenceCount,
                                    passiveSentencesPercentage: result.passiveSentencesPercentage,
                                }}
                            />
                        </span>
                    </p>

                    <Table
                        columns={[{
                            id: "sentence",
                            label: {displayName: intl.formatMessage({id: "sentence"})},
                        }]}
                        data={results}
                        setData={setResults}
                    />
                </div>
            </Modal>
        </>
    );
};

export default PassiveVoiceScoreIndicator;