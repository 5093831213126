import {Link, LinkScore} from "../links/LinksChecker";
import {KeyphrasesForm} from "../../SeoWidget";
import nlp from "compromise";
import {GeneralScore} from "../../../../elements/scoreIndicator/ScoreIndicator";

// Based on https://yoast.com/what-is-anchor-text/#assessment

export type CompetingLink = Link & {
    keyphrase: string,
};

export type LinkFocusScore = {
    score: GeneralScore,
    competingLinks: CompetingLink[]
};

const containsKeyphrase = (linkText: string, keyphrases: string[]): (string | null) => {
    for (let i = 0; i < keyphrases.length; i++) {
        const keyphrase = keyphrases[i];
        if (nlp(linkText).has(keyphrase)) {
            return keyphrase;
        }
    }

    return null;
}

export const checkLinkFocus = (links: LinkScore, seoForm: KeyphrasesForm): LinkFocusScore => {
    const keyphrases = [seoForm.keyphrase, ...seoForm.keyphraseSynonyms];
    const allLinks = [...links.outboundScore.links, ...links.internalScore.links];
    const competingLinks: CompetingLink[] = [];
    allLinks.map((link: Link) => {
        const keyphraseInLink = containsKeyphrase(link.text.toLowerCase(), keyphrases);
        if (keyphraseInLink) {
            competingLinks.push({
                ...link,
                keyphrase: keyphraseInLink
            });
        }
    });

    return {
        score: competingLinks.length > 0 ? "bad" : "good",
        competingLinks: competingLinks
    };
};
