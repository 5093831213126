import {SeoFormProps} from "../../SeoWidget";
import nlp from "compromise";
import {GeneralScore} from "../../../../elements/scoreIndicator/ScoreIndicator";

// Based on https://yoast.com/slug/#h-1-include-your-keyword-in-the-url-slug

export type KeyphraseInSlugScore = {
    score: GeneralScore,
};

export const checkKeyphraseInSlug = (slug: string, form: SeoFormProps): KeyphraseInSlugScore => {
    const doc = nlp(slug.replace("-", " "));
    const containsFocusKeyphrase = doc.has(form.keyphrase);

    return {
        score: containsFocusKeyphrase ? "good" : "bad"
    };
};
