import nlp from "compromise";
import {GeneralScore} from "../../../../elements/scoreIndicator/ScoreIndicator";

// Based on https://blog.hubspot.com/blog/tabid/6307/bid/5984/spilling-seo-juice-3-dos-and-don-ts-for-writing-great-page-titles.aspx

export type TitleLengthScore = {
    score: GeneralScore,
    characterCount: number,
    wordCount: number,
};

export const checkTitleLength = (title: string): TitleLengthScore => {
    const characterCount = title.trim().length;
    const wordCount = nlp(title).wordCount();
    return {
        score: characterCount >= 60 && characterCount <= 70 ? "good" : "bad",
        characterCount: characterCount,
        wordCount: wordCount
    };
};