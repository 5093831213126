import nlp from "compromise";
import allWords from "./words/transitionWords";
import twoPartTransitionWords from "./words/twoPartTransitionWords";
import {GeneralScore} from "../../../elements/scoreIndicator/ScoreIndicator";
import {Language} from "../../../../data/DataProvider";

// Based on: https://yoast.com/transition-words-why-and-how-to-use-them/

export type WordsLanguageMap = {
    [key in Language]: string[];
};

export type WordCombination = [string, string];
export type CombinationWordsLanguageMap = {
    [key in Language]: WordCombination[];
};

export type TransitionWordsScore = {
    score: GeneralScore,
    sentenceCount: number,
    sentencesWithTransition: string[]
    sentencesWithTransitionPercentage: number,
};

export const checkTransitionWords = (text: string, lang: Language): TransitionWordsScore => {
    const doc = nlp(text);

    const allWordsLocale: string[] = allWords(lang);
    const twoPartTransitionWordsLocale: WordCombination[] = twoPartTransitionWords[lang];
    const sentenceCount = doc.sentences().length;
    const sentencesWithTransition: string[] = doc
        .sentences()
        .filter((s: any) => {
            for (let i = 0; i < allWordsLocale.length; i++) {
                if (s.has(allWordsLocale[i])) {
                    return true;
                }
            }

            for (let i = 0; i < twoPartTransitionWordsLocale.length; i++) {
                const combo = twoPartTransitionWordsLocale[i];
                if (s.has(`${combo[0]} * ${combo[1]}`)) {
                    return true;
                }
            }

            return false;
        })
        .out("array");

    const sentencesWithTransitionPercentage = Math.round((sentencesWithTransition.length / sentenceCount) * 100);
    return {
        score: sentencesWithTransitionPercentage >= 30
            ? "good"
            : (sentencesWithTransitionPercentage >= 20 ? "okay" : "bad"),
        sentenceCount: sentenceCount,
        sentencesWithTransition: sentencesWithTransition,
        sentencesWithTransitionPercentage: sentencesWithTransitionPercentage,
    }
};
