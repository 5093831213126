export const defaultEditorValue =
    "<h1>Hello world!</h1>" +
    "<p><strong>Start your article off with an H1, make sure to only have one. To <u>format</u> your <em>text</em>, select your text and a box appears with all available options. Good luck with your text!</strong></p>" +
    "<h2>1. Lorem ipsum dolor sit amet</h2>" +
    "<p>Consectetur adipiscing elit. Etiam eget faucibus tellus. Duis euismod, lacus in <code>elementum</code> facilisis, est velit pellentesque velit, </p>" +
    "<pre class=\"ql-syntax\" spellcheck=\"false\">In dictum libero justo at nisi. Integer egestas enim vel ipsum tristique</pre>" +
    "<p>Avel ornare quam accumsan. Donec finibus non ex ac dictum. Vestibulum ex nibh, fermentum id dui quis, condimentum vulputate massa.</p>" +
    "<h3>1.1 raesent ac dui eget ex iaculis ultricies</h3>" +
    "<p>Curabitur <a href='#'>vitae libero</a> in mauris rhoncus molestie:</p>" +
    "<ol>" +
    "<li>Praesent lacus eros</li>" +
    "<li>facilisis eget molestie ac</li>" +
    "<li>consequat eget ipsum</li>" +
    "</ol>" +
    "<p>Nam magna tellus, porttitor laoreet diam sed, placerat condimentum urna. Duis at risus non felis laoreet mollis sit amet eget diam.</p>" +
    "<ul>" +
    "<li>Ut suscipit consequat </li>" +
    "<li>Tellus sed bibendum</li>" +
    "<li>Donec ornare odio justo</li>" +
    "</ul>" +
    "<p>At sagittis quam viverra eget.</p>";

export const defaultMetaDescription =
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed faucibus " +
    "sollicitudin enim, eget venenatis ligula consectetur vitae. Aliquam " +
    "ullamcorper metus dolor, quis tincidunt.";

export const defaultSlug = "my-first-blog-post";