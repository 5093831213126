import Quill from "quill";

const Inline = Quill.import("blots/inline");

class MarkerBlot extends Inline {
    static blotName = "marker";
    static className = "marker";
    static tagName = "span";

    static formats(): boolean {
        return true;
    }
}

Quill.register("formats/marker", MarkerBlot);

export default MarkerBlot;