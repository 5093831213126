import React, {ReactNode, useEffect, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {Modal, Table, TableData} from "peggirkit";
import {HeadingWithKeyphrases, KeyphraseInSubheadingScore} from "./KeyphraseInSubheadingChecker";
import ScoreIndicator from "../../../../elements/scoreIndicator/ScoreIndicator";
import {globalModalStyling} from "../../../../elements/modal/modalStyle";

type Result = {
    heading: string,
    includedKeyphrases: ReactNode,
};

type Props = {
    result?: KeyphraseInSubheadingScore
};

const KeyphraseInSubheadingScoreIndicator = ({result}: Props) => {
    const intl = useIntl();
    const [showExplanation, setShowExplanation] = useState(false);
    const [tableData, setTableData] = useState<TableData<Result>[]>([]);

    useEffect(() => {
        setTableData(result?.headingsWithKeyphrases.map((headingWithKeyphrases: HeadingWithKeyphrases) => ({
            heading: headingWithKeyphrases.heading,
            includedKeyphrases: (
                <ul className={"list-inside list-disc"}>
                    {headingWithKeyphrases.keyphrases.map((phrase, i) => <li key={i}>{phrase}</li>)}
                </ul>
            )
        })) || []);
    }, [result?.headingsWithKeyphrases]);

    if (!result) {
        return <>-</>;
    }

    return (
        <>
            <ScoreIndicator
                score={result.score}
                onClick={() => setShowExplanation(true)}
            />

            <Modal
                {...globalModalStyling(intl)}
                show={showExplanation}
                onClose={() => setShowExplanation(false)}
                title={intl.formatMessage({id: "keyphraseInSubheading"})}
            >
                <div className={"flex flex-col gap-6"}>
                    <p>
                        <FormattedMessage id="keyphraseInSubheading.description-1"/>
                    </p>
                    <p>
                        <span className={"text-black dark:text-white font-medium"}>
                            <FormattedMessage
                                id="keyphraseInSubheading.description-2"
                                values={{
                                    headingsWithKeyphrasesCount: result.headingsWithKeyphrases.length,
                                    headingCount: result.headingCount,
                                    headingKeyphrasePercentage: result.headingKeyphrasePercentage,
                                }}
                            />
                        </span>
                    </p>

                    <Table<Result>
                        columns={[
                            {
                                id: "heading",
                                label: {displayName: intl.formatMessage({id: "heading"})},
                            },
                            {
                                id: "includedKeyphrases",
                                label: {displayName: intl.formatMessage({id: "includedKeyphrases"})},
                            },
                        ]}
                        data={tableData}
                        setData={setTableData}
                    />
                </div>
            </Modal>
        </>
    );
};

export default KeyphraseInSubheadingScoreIndicator;