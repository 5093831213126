export const boolToYesNo = (val: boolean) => val ? "yes" : "no";

const english = {
    "appName": "Peggir SEO Editor",
    "edit": "Edit",
    "editor": "Editor",
    "new": "New",
    "load": "Load",
    "save": "Save",
    "share": "Share",
    "exportToHtml": "Export to HTML...",
    "exportToMarkdown": "Export to Markdown...",
    "about": "About",
    "options": "Options",
    "scores": "Scores",
    "metadata": "Metadata",
    "startTyping": "Start typing...",
    "contentLanguage": "Content language",
    "contentLanguage.tip": "Your scores may differ between languages.",
    "slug": "Slug",
    "slug.tip": "Exclude unsafe URL characters. E.g. use \"-\" instead of spaces.",
    "slugAddon": "site.com/blog/",
    "description": "Description",
    "n-characters": "{n} character(s)",
    "calculateScore": "Calculate score",
    "collapse": "Collapse",
    "expand": "Expand",
    "fleschKincaid": "Flesch-Kincaid",
    "fleschKincaidDesignedForEnglish": "Flesch–Kincaid readability tests are designed for English texts.",
    "wordComplexityDesignedForEnglish": "Word complexity tests are designed for English texts.",
    "aboutThisScore": "About this score",
    "theseContentScoresIncludeTitle": "These content scores include the title.",
    "readabilityCheckDutchNotSupportedFor": "Dutch not supported for: Passive voice.",
    "content": "Content",
    "title": "Title",
    "readability": "Readability",
    "grade": "Grade",
    "ease": "Ease",
    "words": "Words",
    "sentences": "Sentences",
    "sentence": "Sentence",
    "syllables": "Syllables",
    "fleschKincaidReadabilityTests": "Flesch–Kincaid readability tests",
    "fleschKincaidReadabilityTests.description.1": "For web copy, a reading ease score of 60-70 is considered acceptable. Translated into simpler terms, that means that a good web text should be easily understood by a 13 to 15-year-old student. (Yoast)",
    "fleschKincaidReadabilityTests.description.2": "How to use the Flesch reading ease score to improve your writing: 1. Shorten your sentences; 2. Limit your use of difficult words.",
    "fleschReadingEase.description": "The Flesch–Kincaid readability tests are readability tests designed to indicate how difficult a passage in English is to understand.",
    "close": "Close",
    "score": "Score",
    "level": "Level",
    "note": "Note",
    "fleschKincaid.1.score": "100.00–90.00",
    "fleschKincaid.1.level": "5th grade",
    "fleschKincaid.1.note": "Very easy to read. Easily understood by an average 11-year-old student.",
    "fleschKincaid.2.score": "90.0–80.0",
    "fleschKincaid.2.level": "6th grade",
    "fleschKincaid.2.note": "Easy to read. Conversational English for consumers.",
    "fleschKincaid.3.score": "80.0–70.0",
    "fleschKincaid.3.level": "7th grade",
    "fleschKincaid.3.note": "Fairly easy to read.",
    "fleschKincaid.4.score": "70.0–60.0",
    "fleschKincaid.4.level": "8th & 9th grade",
    "fleschKincaid.4.note": "Plain English. Easily understood by 13- to 15-year-old students.",
    "fleschKincaid.5.score": "60.0–50.0",
    "fleschKincaid.5.level": "10th to 12th grade",
    "fleschKincaid.5.note": "Fairly difficult to read.",
    "fleschKincaid.6.score": "50.0–30.0",
    "fleschKincaid.6.level": "College",
    "fleschKincaid.6.note": "Difficult to read.",
    "fleschKincaid.7.score": "30.0–10.0",
    "fleschKincaid.7.level": "College graduate",
    "fleschKincaid.7.note": "Very difficult to read. Best understood by university graduates.",
    "fleschKincaid.8.score": "10.0–0.0",
    "fleschKincaid.8.level": "Professional",
    "fleschKincaid.8.note": "Extremely difficult to read. Best understood by university graduates.",
    "acceptableFleschKincaidScore": "For web copy, a reading ease score of 60-70 is considered acceptable",
    "wordComplexity": "Word complexity",
    "good": "Good",
    "okay": "Okay",
    "bad": "Bad",
    "complexWordsAnalysis": "Complex words analysis",
    "complexWordsAnalysis.description-1": "We regard a word as complex if: It’s longer than 7 characters, it does not appear in the top 5000 most frequent words & it does not start with a capital letter. (Yoast)",
    "complexWordsAnalysis.description-2": "Current text: {nComplexWords} out of {totalWordCount} words are complex ({complexWordsPercentage}%).",
    "complexWords": "Complex words",
    "dictionary": "Dictionary",
    "example": "Example",
    "synonyms": "Synonyms",
    "antonyms": "Antonyms",
    "search": "Search",
    "searchForWord": "Search for word...",
    "paragraphLength": "Paragraph length",
    "sentenceLength": "Sentence length",
    "passiveVoice": "Passive voice",
    "consecutiveSentences": "Consecutive sentences",
    "transitionWords": "Transition words",
    "highlight": "Highlight",
    "removeHighlights": "Remove highlights",
    "sentenceLengths": "Sentence lengths",
    "sentenceLengths.description-1": "Counts the words you’ve used in each sentence. Next, it calculates how many of your sentences are longer than 20 words. If more than 25% of your sentences is longer than 20 words, you’ll get the advice to shorten them. (Yoast)",
    "sentenceLengths.description-2": "Result: {longSentences}/{sentenceCount} ({longSentencesPercentage}%) sentences too long.",
    "paragraphLengths": "Paragraph lengths",
    "paragraphLengths.description-1": "We advise you to keep the length of your paragraphs below 150 words. If you do that, you’ll score a green feedback bullet. You’ll get an orange bullet if your paragraphs contain more than 150 but less than 200 words. With the bullet comes the advice to shorten your paragraph(s). If you exceed 200 words, your feedback bullet will be red. (Yoast)",
    "passiveSentences": "Passive sentences",
    "passiveSentences.description-1": "You’ll get a green bullet if less than 10% of your sentences have passive voice. It’ll turn red if you go over 15%. Passive voice makes your writing more distant, and your message less clear. So, try to write in an active voice — it will make your writing much stronger! (Yoast)",
    "passiveSentences.description-2": "Result: {passiveSentences}/{sentenceCount} ({passiveSentencesPercentage}%) sentences passively voiced.",
    "consecutiveSentences.description-1": "If three or more consecutive sentences begin with the same word, you’ll be flagged. The assumption here is that so much repetition can annoy the reader. (Yoast)",
    "consecutiveSentences.description-2": "Result: Out of a total of {sentenceCount} sentence(s), you had {numOfConsecutiveSentences} instance(s) of repeating sentence(s), with the longest streak of {longestStreak}.",
    "subheadingDistribution": "Subheading distribution",
    "subheadingDistribution.description-1": "The subheading distribution check assesses whether you’ve used enough subheadings in your text. Most texts of over 300 words need subheadings, to help readers scan the text. So, this check will notify you if your text is longer than 300 words and doesn't contain any subheadings. It’ll also let you know if a text section following a subheading is too long — i.e., more than 300 words –, and suggest you add subheadings to improve the readability of that part of the text. (Yoast)",
    "section": "Section",
    "noHeading": "No heading",
    "transitionWords.description-1": "The transition words check assesses whether or not you use enough transition words. If at least 30% of the sentences in your text contain a transition word, the bullet will be green. You get an orange bullet if you use them in more than 20%, or in less than 30% of your sentences. The bullet will be red if less than 20% of the sentences of your text contain a transition word. That’s less than 1 in 5 sentences. (Yoast)",
    "transitionWords.description-2": "Result: {sentencesWithTransition}/{sentenceCount} ({sentencesWithTransitionPercentage}%) sentences contain transition word(s).",
    "seo": "SEO",
    "outboundLinks": "Outbound links",
    "internalLinks": "Internal links",
    "internalLinks.description-1": "Internal links connect your content and give Google an idea of the structure of your website. They can establish a hierarchy on your site, allowing you to provide the most important pages and posts with more link value than other, less valuable pages. So using the right internal linking strategy can boost your SEO. (Yoast)",
    "internalLinks.description-2": "How many internal links should I include per post? There's no set number, but 5-10 for every 2,000 words is a good best practice. (Neil Patel)",
    "internalLinks.description-3": "Peggir score is green if at least 5 links per 2,000 words. Red if there are no internal links.",
    "internalLinks.description-4": "Result: {linkCount}/{linksRecommended} recommended internal links (based on {wordCount} words).",
    "outboundLinks.description-1": "This check scans your text to see if there are any outbound links in your post or on your page. In case it doesn’t find one, we advise you to add at least one outbound to your content. (Yoast)",
    "outboundLinks.description-2": "Result: {count} outbound links.",
    "text": "Text",
    "href": "Href",
    "linkFocus": "Link focus",
    "linkFocus.description-1": "This check warns you when it detects competing links in your text. A link is considered a competing link when its anchor text contains the focus keyphrase that you want your page to rank for. So, the link focus keyphrase check assesses the links on a page and their anchor text and notifies you if you have competing links. The check will also look for the synonyms of your keyphrase.",
    "linkFocus.description-2": "Let’s consider an example. Say you have written an article about potato chips, so your focus keyphrase is ‘potato chips.’ If you then use a link with the anchor text ‘potato chips’ in that same post, that link is considered a competing link. (Yoast)",
    "linkFocus.description-3": "When we detect a competing link, you get a red mark.",
    "linkFocus.description-4": "Result: {count} competing links.",
    "keyphrase": "Keyphrase",
    "enterKeyphrase": "Enter a keyphrase.",
    "valueMustBeBetween": "Must be between {min}-{max} character(s).",
    "keyphraseSynonyms": "Keyphrase synonyms",
    "keyphraseSynonymsCommaSeparated": "Keyphrase synonyms (comma-separated)",
    "keyphraseInIntroduction": "Keyphrase in intro.",
    "keyphraseInIntroduction.description-1": "Checks to make sure that you’ve included your focus keyword in the introduction of your text. Preferably, you should use your focus keyphrase in the very first sentence to make your topic clear immediately. But it’s also important that your text has a natural flow, so try not to force anything. It will take keyword synonyms also into account when analyzing your introduction.",
    "keyphraseInIntroduction.description-2": "If it triggers a red or orange bullet point, it may be due to these reasons: You’ve mentioned your subject, but not your focus keyphrase; You started with an anecdote to attract attention, but the anecdote doesn’t include your focus keyphrase; You’ve mentioned your keyphrase (or its synonyms), but not within the same sentence; You’ve used a synonym for your keyphrase in the introduction, but not told Yoast SEO Premium it is a synonym. Yoast SEO gives you a green bullet point when it thinks you did a good job with the focus keyphrase in the introduction. (Yoast)",
    "keyphraseInIntroduction.description-3": "We consider your very first paragraph to be your introduction. Green: Focus keyphrase in first sentences. Orange: Focus keyphrase or synonym present. Red: No keyphrase present in introduction.",
    "keyphraseInIntroduction.description-4": "Focus keyphrase present: {focusKeyphrasePresent} - focus keyphrase in first sentence: {focusKeyphraseInFirstSentence} - synonym present: {synonymPresent}",
    "yes": "Yes",
    "no": "no",
    "keyphraseDistribution": "Keyphrase distribution",
    "keyphraseDistribution.description-1": "Keyphrase distribution check assesses whether you’ve distributed your focus keyphrase evenly over the copy you’ve written. It also takes the synonyms you’ve entered into account. The check scans your copy and determines where you’ve used the words from your keyphrase in your text.",
    "keyphraseDistribution.description-2": "If you stay on topic throughout your text and use synonyms now and then, you’ll get a green traffic light. If, for instance, you use the words from your focus keyphrase or its synonyms a lot in the beginning, but you don’t mention them at the end, you’ll get an orange or red traffic light. Clicking on the highlight button will reveal which parts of the text already contain your keyphrase. That way, you’ll see which parts of your text need improvement. (Yoast)",
    "keyphraseDistribution.description-3": "Green if keyphrase distance within 10% of average distance, orange if within 15% distance, else red.",
    "keyphraseDistribution.description-4": "Result: Keyword distance {keywordDistance}, average distance {averageDistance}, percentage difference {percentageDifference}",
    "keyphraseDensity": "Keyphrase density",
    "keyphraseDensity.description-1": "Keyphrase density is the number of times your focus keyphrase occurs in your copy, compared to the total text of that page. So if you have a text that is 100 words and 5 of those are your focus keyphrase, your keyphrase density is 5%.",
    "keyphraseDensity.description-2": "Using synonyms is a great way to avoid repetitiveness. Synonyms do not count towards your keyphrase density score, as that exclusively looks at the use of your actual focus keyphrase. (Yoast)",
    "keyphraseDensity.description-3": "You’ll get a green bullet if your keyphrase density lies between 0.5 and 3%.",
    "keyphraseDensity.description-4": "Result: {keyphraseWordCount}/{wordCount} ({densityPercentage}%) words are keyphrases.",
    "suggestion": "Suggestion",
    "keyphraseLength": "Keyphrase length",
    "keyphraseLength.description-1": "Checks whether the number of (content) words in the keyphrase is within the recommended limit. (Yoast)",
    "keyphraseLength.description-2": "Score is green when your keyphrase consists of 1-4 content words. Orange when keyphrase contains 1-6 words, including function words. Otherwise, red.",
    "keyphraseInTitle": "Keyphrase in title",
    "keyphraseInTitle.description-1": "It checks if you’re using your keyphrase in the SEO title of your post or page. The plugin check for this is quite strict. Since Google uses the title to figure out your page’s topic, not having the focus keyphrase in the SEO title may harm your rankings. In addition, potential visitors are much more likely to click on a search result that exactly matches what they were looking for. For the best results, you should try to add your keyphrase at the beginning of the SEO title.",
    "keyphraseInTitle.description-2": "Sometimes, when you’re optimizing for a high-competition keyword, everyone will have the keyword at the beginning of the SEO title. In that case, you can try making it stand out by putting one or two words in front of your focus keyword, thereby slightly “indenting” your result. If you start your SEO title with “the”, “a”, “who” or another function word followed by your keyphrase, you’ll still get a green traffic light. (Yoast)",
    "keyphraseInTitle.description-3": "Score: If title start with focus keyphrase or with a function word followed by your keyphrase, you'll get a green score. You get an orange score if your title contains your focus keyphrase somewhere, if it doesn't you'll get a red score.",
    "keyphraseInDescription": "Keyphrase in description",
    "keyphraseInDescription.description-1": "Checks if you use the words from your focus keyphrase in the meta description text. In addition, it also takes into account the synonyms you enter.",
    "keyphraseInDescription.description-2": "If you don’t mention the keyphrase in the meta description at all, you’ll get a red traffic light. So, make sure to write one. But don’t stuff your meta description with your keyphrase. And make sure to mention all the words from your keyphrase near to each other. Search engines are pretty smart nowadays, but you still need to make it clear what your page is about.",
    "keyphraseInDescription.description-3": "Takes the synonyms you’ve added into account when it performs its analysis. This allows you to write more naturally and will result in a text that’s a more pleasant read. Moreover, it’s easier to score a green traffic light this way. Use it to your advantage! (Yoast)",
    "keyphraseInDescription.description-4": "Score: Green if description contains focus keyphrase or synonym (sentence must contain all words from the phrase). Orange if keyphrase density is too high (40% for now). Red when no keyphrase present in description.",
    "keyphraseInDescription.description-5": "Result: {keyphrasesInDescriptionCount} keyphrase(s) found in your description. {keyphrasesInDescriptionWordCount}/{descriptionWordCount} ({densityPercentage}%) words in your description are from keyphrases.",
    "keyphraseInSubheading.description-1": "You’ll get a green traffic light if you use the keyphrase (or synonyms) in 30 to 75% of your subheadings. Keep in mind that we’ll only check your H2 and H3 subheadings. (Yoast) We check for exact matches.",
    "keyphraseInSubheading.description-2": "Result: {headingsWithKeyphrasesCount}/{headingCount} ({headingKeyphrasePercentage}%) of your headers (H1, H2) contain at least one keyphrase.",
    "heading": "Heading",
    "includedKeyphrases": "Included keyphrases",
    "keyphraseInSubheading": "Keyphrase in subheading",
    "keyphraseInSlug": "Keyphrase in slug",
    "keyphraseInSlug.description-1": "Checks whether the slug contains your focus keyphrase. Your article or page should live on an easy to remember, focused and SEO-friendly URL.",
    "keyphraseInSlug.description-2": "Your focus keyphrase should always be in the slug. It has to make clear what your page is about immediately. The slug that’s generated by default may include function words like “a”, “the” and “and” and similar words. In some cases, you might need those in it to clarify what your page is about, but usually, you can leave them out. Try to use lowercase letters only in your slug. While people sometimes advise to keep URL length (so not just the slug but the entire URL) under 100 characters, or even 70 characters, I’d say it’s most important to make sure your slug (and the rest of the URL) is concise, clear and descriptive. (Yoast)",
    "keyphraseInSlug.description-3": "Score is green when slug contains focus keyphrase. Otherwise, red.",
    "descriptionLength": "Description length",
    "descriptionLength.description-1": "This meta description length assessment measures whether your description is too short (less than 120 characters) or too long (more than 156 characters).  When your meta description has the right length, you’ll get a green traffic light. If it’s too long, or too short, you’ll get an orange traffic light in the SEO analysis. (Yoast)",
    "descriptionLength.description-2": "Result: {characterCount} character(s).",
    "contentLength": "Content length",
    "contentLength.description-1": "It calculates how many words you’ve added on a page and whether you have enough words to rank.",
    "contentLength.description-2": "Every page on your site needs to contain a certain number of words to be able to rank. How long your text should minimally be, depends on the type of page. Taxonomy pages require (slightly) less content than blog posts, whereas cornerstone content should be exhaustive and therefore needs to contain a significant number of words.",
    "contentLength.description-3": "In the table below, you can see how we assess the different pages. If you have less than the minimum number of words on a page, you’ll get a red bullet point in the Yoast feedback. Conversely, you get a green bullet point when the length of your post or page is good.",
    "contentLength.description-4": "Taxonomy page > 250 words; Regular post or page > 300 words; Cornerstone content page > 900 words; Product page or description > 200 words. (Yoast)",
    "contentLength.description-5": "Peggir SEO assistant is primarily meant for blog posts. You'll get a green score if you have > 300 words, orange when 200-300 words and red when < 200 words.",
    "contentLength.description-6": "Result: {wordCount} words in content.",
    "titleLength": "Title length",
    "titleLength.description-1": "If you’re using competitive keywords, a long headline is often more effective. In fact, according to a Backlinko content study from 2020, 14-17 word headlines bring 76.7% more social shares than short headlines.",
    "titleLength.description-2": "Page titles don’t have the strict character limits that keep a title tag from getting cut off in the SERPs. While you don’t need to worry about Google cutting your title off, for a page title to be useful for SEO it should still stick to around 60-70 characters.",
    "titleLength.description-3": "Your page title should be long enough to entice the reader, but short enough to make the meaning and purpose of the page clear to both users and search engines. (HubSpot).",
    "titleLength.description-4": "Result: {characterCount}/60-70 characters ({wordCount} words, green score for 60-70 characters).",
    "paragraph": "Paragraph",
    "discardCurrentProject": "Discard current project?",
    "discardCurrentProject.description": "Are you sure you want to discard the current project? Any unsaved changes will be lost.",
    "cancel": "Cancel",
    "continue": "Continue",
    "autoSave": "Auto-save",
    "autoSavedOn": "Auto-saved on {time}",
    "welcomeNotification.title": "Welcome to Peggir SEO Editor!",
    "welcomeNotification.description": "This editor is still in development. Some scores may not be correct. Use at your own risk!",
    "aboutPeggirSeoEditor": "About Peggir SEO Editor",
    "aboutPeggirSeoEditor.general": "General",
    "aboutPeggirSeoEditor.general.p-1": "This tool is meant as an assistent for writing SEO texts. In the sidebar you'll find some metrics that'll score your texts. In the \"metadata\"-tab, you can add your description, slug & language.",
    "aboutPeggirSeoEditor.general.p-2": "Peggir SEO Editor is still a work-in-process. Some scores & features may be missing, incorrect or buggy. Use at your own risk!",
    "aboutPeggirSeoEditor.general.p-3": "Metrics are based on Yoast SEO (open-source). Using WordPress? Consider using their plugin.",
    "aboutPeggirSeoEditor.general.p-4": "© Peggir 2024",
    "aboutPeggirSeoEditor.privacy": "Privacy",
    "aboutPeggirSeoEditor.privacy.p-1": "This tool does not collect any data.",
    "aboutPeggirSeoEditor.privacy.p-2": "There are no analytics & none of your edited data gets transferred to us. Auto-saved data gets stored locally on your web browser.",
}

export default english;