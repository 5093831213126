import React, {useEffect, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {Modal, Table, TableData} from "peggirkit";
import {KeyphraseInDescriptionScore} from "./KeyphraseInDescriptionChecker";
import ScoreIndicator from "../../../../elements/scoreIndicator/ScoreIndicator";
import {globalModalStyling} from "../../../../elements/modal/modalStyle";

type TableRecord = {
    keyphraseInDescription: string,
};

type Props = {
    result?: KeyphraseInDescriptionScore
};

const KeyphraseInDescriptionScoreIndicator = ({result}: Props) => {
    const intl = useIntl();
    const [showExplanation, setShowExplanation] = useState(false);
    const [tableData, setTableData] = useState<TableData<TableRecord>[]>([]);

    useEffect(() => {
        setTableData(result?.keyphrasesInDescription.map((keyphrase: string) => ({
            keyphraseInDescription: keyphrase
        })) || []);
    }, [result?.keyphrasesInDescription]);

    if (!result) {
        return <>-</>;
    }

    return (
        <>
            <ScoreIndicator
                score={result.score}
                onClick={() => setShowExplanation(true)}
            />

            <Modal
                {...globalModalStyling(intl)}
                show={showExplanation}
                onClose={() => setShowExplanation(false)}
                title={intl.formatMessage({id: "keyphraseInDescription"})}
            >
                <div className={"flex flex-col gap-6"}>
                    <p>
                        <FormattedMessage id="keyphraseInDescription.description-1"/>
                    </p>
                    <p>
                        <FormattedMessage id="keyphraseInDescription.description-2"/>
                    </p>
                    <p>
                        <FormattedMessage id="keyphraseInDescription.description-3"/>
                    </p>
                    <p>
                        <FormattedMessage id="keyphraseInDescription.description-4"/>
                    </p>
                    <p>
                        <span className={"text-black dark:text-white font-medium"}>
                            <FormattedMessage
                                id="keyphraseInDescription.description-5"
                                values={{
                                    keyphrasesInDescriptionCount: result.keyphrasesInDescription.length,
                                    keyphrasesInDescriptionWordCount: result.keyphrasesInDescriptionWordCount,
                                    descriptionWordCount: result.descriptionWordCount,
                                    densityPercentage: result.densityPercentage,
                                }}
                            />
                        </span>
                    </p>
                    <Table<TableRecord>
                        columns={[
                            {
                                id: "keyphraseInDescription",
                                label: {displayName: intl.formatMessage({id: "keyphraseInDescription"})},
                            }
                        ]}
                        data={tableData}
                        setData={setTableData}
                    />
                </div>
            </Modal>
        </>
    );
};

export default KeyphraseInDescriptionScoreIndicator;