import Quill from "quill";
import MarkerBlot from "./MarkerBlot";
import {SetHasMarkedContentFn} from "../../data/DataProvider";

const customGetText = (quill: Quill): string => {
    return quill
        .getContents()
        .filter(op => {
            return typeof op.insert === "string" || op.insert.image;
        })
        .map(op => {
            if (op.insert.image) {
                return op.insert.image = "i";
            }
            return op.insert;
        })
        .join("");
}

const getIndicesOf = (searchStr: string, totalText: string, caseSensitive: boolean): number[] => {
    const searchStrLen = searchStr.length;
    if (searchStrLen == 0) {
        return [];
    }

    if (!caseSensitive) {
        totalText = totalText.toLowerCase();
        searchStr = searchStr.toLowerCase();
    }

    let startIndex = 0;
    let index;
    const indices = [];

    while ((index = totalText.indexOf(searchStr, startIndex)) > -1) {
        indices.push(index);
        startIndex = index + searchStrLen;
    }

    return indices;
}

export const markText = (
    setHasMarkedContent: SetHasMarkedContentFn,
    searchText: string,
    quill: Quill,
    caseSensitive: boolean = true): void => {
    const totalText = customGetText(quill);
    const regExp = new RegExp(searchText, "g");
    const match = regExp.test(totalText);

    if (match) {
        const indices = getIndicesOf(searchText, totalText, caseSensitive);
        const length = searchText.length;
        indices.forEach(index => quill.formatText(index, length, MarkerBlot.blotName, true));
        setHasMarkedContent(true);
    }
};

export const markTexts = (
    setHasMarkedContent: SetHasMarkedContentFn,
    searchTexts?: string[],
    quill?: Quill,
    unmark: boolean = true,
    caseSensitive: boolean = true,
): void => {
    if (!searchTexts || !quill) {
        return;
    }

    if (unmark) {
        unmarkText(quill);
    }

    searchTexts.map(txt => markText(setHasMarkedContent, txt, quill, caseSensitive));
    setHasMarkedContent(true);
};

export const unmarkText = (quill: Quill, setHasMarkedContent?: SetHasMarkedContentFn): void => {
    quill.formatText(0, quill.getText().length, MarkerBlot.blotName, false);

    if (setHasMarkedContent) {
        setHasMarkedContent(false);
    }
};
