import React from "react";
import {TabControl, TabControlButton, TabControlPage} from "peggirkit";
import {useIntl} from "react-intl";
import MetadataSettings from "../metadata/MetadataSettings";
import SidebarScores from "./scores/SidebarScores";


const WidgetsBar = () => {
    const intl = useIntl();

    return (
        <div className={"flex flex-col gap-12 md:gap-12"}>
            <TabControl tabsListContainerClassname={"px-6"}>
                <TabControlPage
                    unmount={false}
                    tabButton={
                        <TabControlButton>
                            {intl.formatMessage({id: "scores"})}
                        </TabControlButton>
                    }
                >
                    <SidebarScores/>
                </TabControlPage>
                <TabControlPage
                    unmount={false}
                    tabButton={
                        <TabControlButton>
                            {intl.formatMessage({id: "metadata"})}
                        </TabControlButton>
                    }
                >
                    <MetadataSettings/>
                </TabControlPage>
            </TabControl>
        </div>
    );
};

export default WidgetsBar;