import nlp from "compromise";
import {GeneralScore} from "../../../elements/scoreIndicator/ScoreIndicator";

// Based on: https://yoast.com/features/consecutive-sentence-check/

export type ConsecutiveSentencesScore = {
    score: GeneralScore,
    sentenceCount: number,
    consecutiveSentences: string[][],
    longestStreak: number
};

export const checkConsecutiveSentences = (text: string): ConsecutiveSentencesScore => {
    const doc = nlp(text);
    let prevWord: (string | null) = null;
    let consecutiveSentences: string[] = [];
    let allConsecutiveSentences: string[][] = [];

    const sentences: any[] = doc.sentences();
    sentences.forEach((sentence: any) => {
        const firstWord = sentence.terms().first().text();
        if (firstWord === prevWord) {
            consecutiveSentences.push(sentence.text());
        } else {
            if (consecutiveSentences.length > 1) {
                allConsecutiveSentences.push(consecutiveSentences);
            }
            consecutiveSentences = [sentence.text()];
            prevWord = firstWord;
        }
    });

    let longestStreak = 0;
    allConsecutiveSentences.map((sentences: string[]) =>
        longestStreak = sentences.length > longestStreak ? sentences.length : longestStreak)

    return {
        score: longestStreak >= 3 ? "bad" : "good",
        sentenceCount: sentences.length,
        consecutiveSentences: allConsecutiveSentences,
        longestStreak: longestStreak,
    }
};
