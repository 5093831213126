import React, {useEffect, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {LongParagraph, ParagraphLengthScore} from "./ParagraphLengthChecker";
import ScoreIndicator from "../../../elements/scoreIndicator/ScoreIndicator";
import {Modal, Table, TableData} from "peggirkit";
import {globalModalStyling} from "../../../elements/modal/modalStyle";

type Result = {
    paragraph: string,
    words: number,
};

type Props = {
    result?: ParagraphLengthScore
};

const truncateString = (paragraph: string) => {
    if (paragraph.length <= 41) {
        return paragraph; // No need to truncate
    }

    const start = paragraph.substring(0, 20);
    const end = paragraph.substring(paragraph.length - 20);
    return `${start} ... ${end}`;
}

const ParagraphLengthScoreIndicator = ({result}: Props) => {
    const intl = useIntl();
    const [showExplanation, setShowExplanation] = useState(false);
    const [results, setResults] = useState<TableData<Result>[]>([]);

    useEffect(() => {
        setResults(
            result?.longParagraphs.map((section: LongParagraph) => ({
                paragraph: truncateString(section.text),
                words: section.wordCount,
            })) || []);
    }, [result?.longParagraphs]);

    if (!result) {
        return <>-</>;
    }

    return (
        <>
            <ScoreIndicator
                score={result.score}
                onClick={() => setShowExplanation(true)}
            />

            <Modal
                {...globalModalStyling(intl)}
                show={showExplanation}
                onClose={() => setShowExplanation(false)}
                title={intl.formatMessage({id: "paragraphLengths"})}
            >
                <div className={"flex flex-col gap-6"}>
                    <p>
                        <FormattedMessage id="paragraphLengths.description-1"/>
                    </p>

                    <Table<Result>
                        columns={[
                            {
                                id: "paragraph",
                                label: {displayName: intl.formatMessage({id: "paragraph"})},
                            },
                            {
                                id: "words",
                                label: {displayName: intl.formatMessage({id: "words"})},
                            },
                        ]}
                        data={results}
                        setData={setResults}
                    />
                </div>
            </Modal>
        </>
    );
};

export default ParagraphLengthScoreIndicator;