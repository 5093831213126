import React, {ReactNode, useEffect, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {LinkFocusScore} from "./LinkFocusChecker";
import {Modal, Table, TableData} from "peggirkit";
import ScoreIndicator from "../../../../elements/scoreIndicator/ScoreIndicator";
import {globalModalStyling} from "../../../../elements/modal/modalStyle";

type TableResult = {
    text: string,
    keyphrase: ReactNode,
    href: string,
};

type Props = {
    result?: LinkFocusScore
};

const LinkFocusScoreIndicator = ({result}: Props) => {
    const intl = useIntl();
    const [showExplanation, setShowExplanation] = useState(false);
    const [tableData, setTableData] = useState<TableData<TableResult>[]>([]);

    useEffect(() => {
        setTableData(result?.competingLinks.map(link => ({
            text: link.text,
            keyphrase: <q>{link.keyphrase}</q>,
            href: link.href,
        })) || []);
    }, [result?.competingLinks]);

    if (!result) {
        return <>-</>;
    }

    return (
        <>
            <ScoreIndicator
                score={result.score}
                onClick={() => setShowExplanation(true)}
            />

            <Modal
                {...globalModalStyling(intl)}
                show={showExplanation}
                onClose={() => setShowExplanation(false)}
                title={intl.formatMessage({id: "linkFocus"})}
            >
                <div className={"flex flex-col gap-6"}>
                    <p>
                        <FormattedMessage id="linkFocus.description-1"/>
                    </p>
                    <p>
                        <FormattedMessage id="linkFocus.description-2"/>
                    </p>
                    <p>
                        <FormattedMessage id="linkFocus.description-3"/>
                    </p>
                    <p>
                        <span className={"text-black dark:text-white font-medium"}>
                            <FormattedMessage
                                id="linkFocus.description-4"
                                values={{
                                    count: result.competingLinks.length,
                                }}
                            />
                        </span>
                    </p>

                    <Table<TableResult>
                        columns={[
                            {
                                id: "text",
                                label: {displayName: intl.formatMessage({id: "text"})},
                            },
                            {
                                id: "keyphrase",
                                label: {displayName: intl.formatMessage({id: "keyphrase"})},
                            },
                            {
                                id: "href",
                                label: {displayName: intl.formatMessage({id: "href"})},
                            },
                        ]}
                        data={tableData}
                        setData={setTableData}
                    />
                </div>
            </Modal>
        </>
    );
};

export default LinkFocusScoreIndicator;