import React, {ReactNode} from "react";

export type WidgetTabPageProps = {
    name: string,
    children: ReactNode | string,
};

const WidgetTabPage = ({name, children}: WidgetTabPageProps) => {
    return <></>; // This is a dummy wrapper
};

export default WidgetTabPage;