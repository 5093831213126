import {KeyphrasesForm} from "../../SeoWidget";
import nlp from "compromise";
import Three from "compromise/types/view/three";
import {GeneralScore} from "../../../../elements/scoreIndicator/ScoreIndicator";

// Based on https://yoast.com/keyphrase-distribution-what-it-is-and-how-to-balance-it/

export type KeyphraseDistributionScore = {
    score: GeneralScore,
    keywordDistance: number,
    averageDistance: number,
    percentageDifference: number,
};

const getKeyphraseCount = (doc: any, keyphrases: string[]): number => {
    let count = 0;
    keyphrases.map(keyphrase => count += doc.match(keyphrase).json().length);
    return count;
};

const getKeyphraseIndices = (doc: any, keyphrases: string[]): number[] => {
    const indices: number[] = [];

    keyphrases.map(keyphrase => {
        doc
            .match(keyphrase, undefined, {})
            .out("offset")
            .map((el: any) => indices.push(el.offset.index));
    });

    return indices;
};

export const checkKeyphraseDistribution = (content: string, form: KeyphrasesForm): KeyphraseDistributionScore => {
    const doc = nlp(content);
    const keyphrases = [form.keyphrase, ...form.keyphraseSynonyms];

    // 3. Find the frequency of the keyword in your document:
    // const keywordFrequency = doc.match(form.keyphrase).out("array").length;
    const keywordFrequency = getKeyphraseCount(doc, keyphrases);

    // 4. Calculate the total number of words in the document:
    const totalWords = doc.terms().length;

    // 5. Calculate the distribution of the keyword in your document:
    // const keywordDistribution = (keywordFrequency / totalWords) * 100;

    // 6. Determine the average distance between each occurrence of the keyword in your document:
    // const keywordIndices = doc.match(form.keyphrase, undefined, {}).out("offset").map((el: any) => el.offset.index);
    const keywordIndices = getKeyphraseIndices(doc, keyphrases);
    const wordDistances = keywordIndices.map((index: number, i: number) => keywordIndices[i + 1] - index).slice(0, -1);
    const averageDistance = wordDistances.reduce((a: number, b: number) => a + b, 0) / wordDistances.length;

    // 7. Determine whether the keyword is used evenly in your document:
    const keywordDistance = totalWords / keywordFrequency;
    const thresholdPercentage = 10;
    const percentageDifference = Math.abs(averageDistance - keywordDistance) / averageDistance * 100;

    return {
        score: percentageDifference <= thresholdPercentage
            ? "good"
            : (percentageDifference <= 15 ? "okay" : "bad"),
        averageDistance: Math.round(averageDistance),
        keywordDistance: Math.round(keywordDistance),
        percentageDifference: Math.round(percentageDifference),
    };
};