import React, {useState} from "react";
import Widget, {CalculateScoreFn, CalculateScoreParams} from "../Widget";
import {FormattedMessage, useIntl} from "react-intl";
import {findContentTitle} from "../../../editor/editorUtils";
import WidgetTabControl from "../../elements/tabControl/WidgetTabControl";
import WidgetTabPage from "../../elements/tabControl/WidgetTabPage";
import FootNote from "../../elements/footNote/FootNote";
import {checkParagraphLengths, ParagraphLengthScore} from "./paragraphLength/ParagraphLengthChecker";
import {checkSentenceLengths, SentenceLengthScore} from "./sentenceLength/sentenceLengthChecker";
import {checkPassiveVoice, PassiveVoiceScore} from "./passiveVoice/PassiveVoiceChecker";
import {checkConsecutiveSentences, ConsecutiveSentencesScore} from "./consecutiveSentences/ConsecutiveSentencesChecker";
import {
    checkSubheadingDistribution,
    SubheadingDistributionScore
} from "./subheadingDistribution/SubheadingDistributionChecker";
import {checkTransitionWords, TransitionWordsScore} from "./transitionWords/TransitionWordsChecker";
import ReadabilityScoreList from "./ReadabilityScoreList";
import {Language, useEditor} from "../../../data/DataProvider";
import {Alert} from "peggirkit";

export type ReadabilityScoresProps = {
    sentenceLength: SentenceLengthScore,
    paragraphLength: ParagraphLengthScore,
    passiveVoice: PassiveVoiceScore,
    consecutiveSentences: ConsecutiveSentencesScore,
    subheadingDistribution?: SubheadingDistributionScore,
    transitionWords?: TransitionWordsScore,
};

const ReadabilityWidget = () => {
    const intl = useIntl();
    const {contentLanguage} = useEditor();
    const [contentScores, setContentScores] = useState<ReadabilityScoresProps>();
    const [titleScores, setTitleScores] = useState<ReadabilityScoresProps>();
    const [descriptionScores, setDescriptionScores] = useState<ReadabilityScoresProps>();

    const calculateScore: CalculateScoreFn = ({
                                                  editor,
                                                  contentValue,
                                                  metaDescription,
                                                  contentLanguage
                                              }: CalculateScoreParams) => {
        if (editor) {
            setContentScores({
                paragraphLength: checkParagraphLengths(editor.getText()),
                sentenceLength: checkSentenceLengths(editor.getText()),
                passiveVoice: checkPassiveVoice(editor.getText()),
                consecutiveSentences: checkConsecutiveSentences(editor.getText()),
                subheadingDistribution: checkSubheadingDistribution(contentValue),
                transitionWords: checkTransitionWords(editor.getText(), contentLanguage.value as Language),
            });
        }

        const title = findContentTitle(contentValue);
        setTitleScores({
            paragraphLength: checkParagraphLengths(title),
            sentenceLength: checkSentenceLengths(title),
            passiveVoice: checkPassiveVoice(title),
            consecutiveSentences: checkConsecutiveSentences(title),
            subheadingDistribution: undefined,
            transitionWords: undefined,
        });

        setDescriptionScores({
            paragraphLength: checkParagraphLengths(metaDescription),
            sentenceLength: checkSentenceLengths(metaDescription),
            passiveVoice: checkPassiveVoice(metaDescription),
            consecutiveSentences: checkConsecutiveSentences(metaDescription),
            subheadingDistribution: undefined,
            transitionWords: undefined,
        });
    };

    return (
        <Widget
            name={intl.formatMessage({id: "readability"})}
            calculateScore={calculateScore}
        >
            {
                contentLanguage.value === "nl-NL" &&
                <Alert
                    containerClassname={"mb-6"}
                    type={"warning"}
                    title={intl.formatMessage({id: "readabilityCheckDutchNotSupportedFor"})}
                />
            }

            <WidgetTabControl>
                <WidgetTabPage name={intl.formatMessage({id: "content"})}>
                    <ReadabilityScoreList score={contentScores}/>
                    <FootNote>
                        <FormattedMessage id={"theseContentScoresIncludeTitle"}/>
                    </FootNote>
                </WidgetTabPage>

                <WidgetTabPage name={intl.formatMessage({id: "title"})}>
                    <ReadabilityScoreList score={titleScores}/>
                </WidgetTabPage>

                <WidgetTabPage name={intl.formatMessage({id: "description"})}>
                    <ReadabilityScoreList
                        score={descriptionScores}
                        withMarkButtons={false}
                    />
                </WidgetTabPage>
            </WidgetTabControl>
        </Widget>
    );
};

export default ReadabilityWidget;