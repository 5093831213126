import nlp from "compromise";
import {GeneralScore} from "../../../elements/scoreIndicator/ScoreIndicator";

// Based on: https://yoast.com/features/passive-voice-check/

type PassiveSentence = {
    text: string,
};

export type PassiveVoiceScore = {
    score: GeneralScore
    sentenceCount: number,
    passiveSentences: PassiveSentence[],
    passiveSentencesPercentage: number,
};

export const checkPassiveVoice = (text: string): PassiveVoiceScore => {
    const doc = nlp(text);
    const nSentences = doc.fullSentences().json().length;
    const passiveSentences: Set<string> = new Set(
        doc
            .match("#Passive")
            .fullSentences()
            .json()
            .map((s: any) => s.text));

    const passivePercentage = Math.round((passiveSentences.size / nSentences) * 100);

    return {
        score: passivePercentage < 10 ? "good" : (passivePercentage > 15 ? "bad" : "okay"),
        sentenceCount: nSentences,
        passiveSentences: Array.from(passiveSentences).map(sentence => ({text: sentence})),
        passiveSentencesPercentage: passivePercentage
    }
};