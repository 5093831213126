import React, {useState} from "react";
import {ActionRowButton, ActionRowButtonProps, ConfirmModal, Icon} from "peggirkit";
import {DocumentIcon} from "@heroicons/react/24/outline";
import {FormattedMessage, useIntl} from "react-intl";
import {buttonStyling} from "./projectButtons";
import {globalModalResponsiveStyling} from "../../../widgets/elements/modal/modalStyle";
import {useEditor} from "../../../data/DataProvider";

const NewProjectButton = (props: Partial<ActionRowButtonProps>) => {
    const intl = useIntl();
    const [showModal, setShowModal] = useState(false);
    const {clearProject} = useEditor();

    return (
        <>
            <ActionRowButton
                {...props}
                {...buttonStyling}
                iconBefore={<Icon icon={DocumentIcon}/>}
                buttonAction={{as: "button", actionProps: {onClick: () => setShowModal(true)}}}
                title={intl.formatMessage({id: "new"})}
            >
                <FormattedMessage id={"new"}/>
            </ActionRowButton>

            <ConfirmModal
                {...globalModalResponsiveStyling}
                type={"warning"}
                loading={false}
                show={showModal}
                onClose={() => setShowModal(false)}
                title={intl.formatMessage({id: "discardCurrentProject"})}
                dismissButton={{
                    text: intl.formatMessage({id: "cancel"}),
                }}
                continueButton={{
                    text: intl.formatMessage({id: "continue"}),
                    onClick: (closeFn) => {
                        clearProject();
                        closeFn();
                    },
                }}
            >
                <FormattedMessage id={"discardCurrentProject.description"}/>
            </ConfirmModal>
        </>
    );
};

export default NewProjectButton;